import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl, FormControlLabel, styled } from "@mui/material";
import { ControlledBootstrapInput } from "../form/Input";
import Modal from "./Modal";
import Cross from "../../assets/icons/cross.svg";
import CustomCheckbox from "../form/Checkbox";
import { Controller, useForm } from "react-hook-form";
import { ModalProps } from "../../interface/interface";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { registerUser } from "../../api/user";
import { toast } from "react-toastify";
import axios from "axios";
import { ControlledDatePicker } from "../form/DatePicker";
import { formatDate, parseDate } from "../../utils";
import { ControlledSelect } from "../form/Select";
import { useTranslation } from "react-i18next";
import modalStore from "../../store/modalStore";
import { t } from "i18next";

const schema = yup
  .object({
    name: yup.string().required(t("ModalError.Enter your name")),
    password: yup.string().min(8).max(100),
    password2: yup.string().min(8).max(100),
    // age: yup.number().positive().integer().required(),
    email: yup
      .string()
      .required(t("ModalError.Enter email"))
      .email(t("ModalError.Email entered incorrectly")),
    agree_to_policy: yup
      .boolean()
      .required()
      .oneOf([true], t("ModalError.To register, you must accept the policy")),
    mailing: yup.boolean(),
    form_after_test: yup.boolean(),
    part_in_contest: yup.boolean(),
  })
  .required();

export function ModalRegistration(props: ModalProps) {
  let { t } = useTranslation();

  const {
    control,
    setError,
    watch,
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      password: "",
      password2: "",
      sex: 2,
      home: "",
      birthday: "",
      email: "",
      agree_to_policy: false,
      mailing: false,
      form_after_test: false,
      part_in_contest: false,
    },
  });


  const handleClose = () => props.setOpen(false);
  watch((v) => {
    console.log(v);
  });

  const onSubmit = async (data) => {
    // Username is the same as email
    data.username = data.email;

    try {
      data.birthday = formatDate(parseDate(data.birthday));
    } catch (e) {
      setError("birthday", { message: t("ModalError.Invalid date") });
      return;
    }

    if (data.password != data.password2) {
      setError("password", { message: t("ModalError.Passwords do not match") });
      return;
    }

    try {
      let result = await registerUser(data);
      reset();
      toast.success(
        t(
          "Toast.Registration.Registration was successful, please wait for an email with a confirmation link"
        )
      );
      handleClose();
    } catch (e) {
      // Get errors from backend and tell react hook form
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response.data) {
            let error = "";
            Object.entries(e.response.data).forEach((entry) => {
              const [key, value]: [string, Array<string>] = entry as [
                string,
                string[]
              ];

              setError(key as any, {
                message: value.join(","),
              });
              error += `${key}: ${value}`;
            });
            toast.error(t("Toast.ResetPassword.CheckData"));
          }
        } else {
          toast.error(t("Toast.ResetPassword.Error"));
        }
      } else {
        toast.error(t("Toast.ResetPassword.UnknownError"));
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="ModalRegistration modal">
            <div className="modal-title-cross">
              <h2 className="modal-title" id="transition-modal-title">
                {t("ModalRegistration.title")}
              </h2>
              <img
                className="modal-cross"
                onClick={handleClose}
                src={Cross}
                alt=""
              />
            </div>
            <div className="ModalRegistrationForm">
              <div className="ModalRegistrationFormContainer">
                <ControlledBootstrapInput
                  control={control}
                  name="name"
                  placeholder={t("form.placeholder.name")}
                  label={t("form.label.name")}
                  fullWidth
                />
                <ControlledSelect
                  control={control}
                  name="sex"
                  label={t("form.label.sex")}
                  placeholder={t("form.placeholder.sex")}
                  fullWidth
                  defaultValue={2}
                  options={[
                    {
                      name: t("form.selectOption.male"),
                      value: 0,
                    },
                    {
                      name: t("form.selectOption.female"),
                      value: 1,
                    },
                    {
                      name: t("form.selectOption.unspecified"),
                      value: 2,
                    },
                  ]}
                />
              </div>
              <div className="ModalRegistrationFormContainer">
                <div className="ModalRegistrationFormContainer__element ModalRegistrationInputDate">
                  <ControlledDatePicker
                    fullWidth
                    control={control}
                    name="birthday"
                    label={t("form.label.birthday") + "*"}
                  />
                </div>
                <div className="ModalRegistrationFormContainer__element">
                  <ControlledBootstrapInput
                    control={control}
                    name="home"
                    placeholder={t("form.placeholder.home")}
                    label={t("form.label.home")}
                    fullWidth
                  />
                </div>
              </div>
              <div className="ModalBorder"></div>
              <div className="ModalRegistrationFormContainer">
                <div className="ModalRegistrationFormContainer__element">
                  <ControlledBootstrapInput
                    control={control}
                    name="email"
                    placeholder={"example@gmail.com"}
                    label={t("form.label.Email*")}
                    fullWidth
                    type="email"
                  />
                </div>
                <div className="ModalRegistrationFormContainer__element">
                  <span className="ModalRegistrationSpanInfo">
                    {t("ModalRegistration.confirmationInfo")}
                  </span>
                </div>
              </div>
              <div className="ModalRegistrationFormContainer">
                <div className="ModalRegistrationFormPassword">
                  <ControlledBootstrapInput
                    control={control}
                    name="password"
                    label={t("form.label.password") + "*"}
                    fullWidth
                    type="password"
                  />
                  <span>
                    {t("ModalRegistration.*A-Z, a-z, 0-9, *#!&?, minimum 6")}
                  </span>
                </div>
                <div className="ModalRegistrationFormPassword">
                  <ControlledBootstrapInput
                    control={control}
                    name="password2"
                    label={t("form.label.repeatPassword") + "*"}
                    fullWidth
                    type="password"
                  />
                </div>
              </div>

              <div className="ModalRegistrationFormCheckbox">
                <div className="ModalRegistrationFormContainer">
                  <div className="ModalRegistrationFormContainer__element ModalRegistrationCheckbox">
                    <Controller
                      name="form_after_test"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field, fieldState }) => (
                        <FormControlLabel
                          value="end"
                          control={<CustomCheckbox {...field} />}
                          label={t("form.label.socialSurveyQuestionnaire")}
                          labelPlacement="end"
                        />
                      )}
                    />
                  </div>
                  <div className="ModalRegistrationFormContainer__element ModalRegistrationCheckbox">
                    <Controller
                      name="mailing"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field, fieldState }) => (
                        <FormControlLabel
                          value="end"
                          control={<CustomCheckbox />}
                          label={t("form.label.newsLetter")}
                          labelPlacement="end"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="ModalRegistrationFormContainer">
                  <div className="ModalRegistrationFormContainer__element ModalRegistrationCheckbox">
                    <Controller
                      name="part_in_contest"
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field, fieldState }) => (
                        <FormControlLabel
                          value="end"
                          control={<CustomCheckbox />}
                          label={t("form.label.takePart")}
                          labelPlacement="end"
                          {...field}
                        />
                      )}
                    />
                  </div>
                  <div className="ModalRegistrationFormContainer__element ModalRegistrationCheckbox">
                    <Controller
                      name="agree_to_policy"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field, fieldState }) => (
                        <FormControlLabel
                          value="end"
                          control={<CustomCheckbox />}
                          label={t("form.label.processingOfPersonalData")}
                          labelPlacement="end"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              {errors && Object.entries(errors).length > 0 && (
                <ErrorContainer>
                  {Object.entries(errors).map(([key, value]) => (
                    <div>{value.message}</div>
                  ))}
                </ErrorContainer>
              )}
            </div>

            <FormControl variant="standard" margin="none" fullWidth>
              <div className="modalSuccessBtn">
                <GradientButton type="submit" fullWidth>
                  {t("form.button.register")}
                </GradientButton>
              </div>
            </FormControl>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
}

export const ErrorContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
  background: theme.palette.error.main,
  padding: theme.spacing(2),
  color: theme.palette.error.contrastText,
  borderRadius: theme.spacing(2),
  "& div": {
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up("xs")]: {},
  [theme.breakpoints.up("md")]: {},
}));
