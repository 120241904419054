import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "./Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GradientButton from "../Button/GradientButton";
import { FormControl, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { ModalTextProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FlatButton from "../Button/FlatButton";
import userStore from "../../store/userStore";
import { useEffect, useState } from "react";
import { CustomToggleButton } from "../form/ToggleButton";
import { getLanguageList, Language } from "../../api/languages";
import { observer } from "mobx-react";

function ModalSelectLanguage(props: ModalTextProps) {
  let { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        await userStore.downloadLanguages();
      } catch (e) {
        console.error(`error: ${e}`);
      }
    })();
  }, []);
  const [open, setOpen] = useState<boolean>(() => {
    return !(localStorage.getItem("firstVisit") === "true");
  });
  const [activeLanguage, setActiveLanguage] = useState<Language>();
  const handleClose = () => setOpen(false);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: Language
  ) => {
    setActiveLanguage(newLanguage);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="modalSuccessTesting modal">
            <h2 className="modal-title" id="transition-modal-title">
              {props.title}
            </h2>
            <Typography
              className="modal-description"
              id="transition-modal-description"
            ></Typography>
            <div className="ModalToogleBtn">
              {userStore.languages &&
                userStore.languages.map((item) => {
                  return (
                    <ToggleButtonGroup
                      key={item.locale}
                      color="primary"
                      value={activeLanguage}
                      exclusive
                      onChange={handleChange}
                      aria-label="Platform"
                    >
                      <CustomToggleButton value={item}>
                        {item.locale}
                      </CustomToggleButton>
                    </ToggleButtonGroup>
                  );
                })}
            </div>
            <FormControl variant="standard" margin="none" fullWidth>
              <div className="modalSuccessBtn ModalToggleButton">
                <GradientButton
                  onClick={async () => {
                    if (!activeLanguage) {
                      return;
                    }
                    handleClose();
                    localStorage.setItem("firstVisit", "true");
                    let languageChanged = userStore.locale != activeLanguage.locale
                    await userStore.setLanguage(activeLanguage);
                    
                    if (languageChanged) {
                      userStore.saveLanguage();
                    }
                  }}
                  fullWidth
                >
                  {t("selectLanguage.button.SelectAndContinue")}
                </GradientButton>
              </div>
            </FormControl>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default observer(ModalSelectLanguage);
