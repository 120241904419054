
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react'
import modalStore from '../../store/modalStore';
import { AlertModal } from './AlertModal';
import { DialogModal } from './DialogModal';


function GlobalModal() {
    let modalState = modalStore.state;
    let open = modalStore.open;
    let props = modalStore.props;


    let ModalComponent = undefined

    if (modalState && modalState.type == "AlertModal") {
      
      ModalComponent = <AlertModal open={open} setOpen={(open)=> {
        runInAction(()=> {
          modalStore.open = open;
        })
      }} description={props.description} {...props}/>
    }

    if (modalState && modalState.type == 'ModalPrompt') {
      
      ModalComponent = <DialogModal open={open} setOpen={(open)=> {
        runInAction(()=> {
          modalStore.open = open;
        })
      }}{...props}/>
    }

  return (
    <div>{ModalComponent|| ""}</div>
  )
}


export default observer(GlobalModal);