import { styled, ToggleButton } from "@mui/material";

const CustomToggleBtnStyle = styled(ToggleButton)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    textAlign: "center",
    letterSpacing: "-0.02em",
    background:
      "linear-gradient(180deg, rgba(207, 247, 235, 0.5) 0%, rgba(182, 252, 231, 0.5) 100%)",
    fontWeight: 700,
    border: "none",
    outline: "none",
    lineHeight: "18px",
    fonstSize: 18,
    padding: "18px 28px",
    color: "#196050",
    marginRight: 10,
    textTransform: "uppercase",
    "&:hover": {
      background: "linear-gradient(180deg, #4F9681 0%, #237E6E 100%)",
      color: "#ffffff",
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      background: "linear-gradient(180deg, #4F9681 0%, #237E6E 100%)",
      color: "#ffffff",
    },
  },
  [theme.breakpoints.up("md")]: {
    padding: "18px 19.5px",
  },
  [theme.breakpoints.up("lg")]: {},
}));

export const CustomToggleButton = (props) => {
  return (
    <>
      <CustomToggleBtnStyle {...props}></CustomToggleBtnStyle>
    </>
  );
};
