import { styled } from '@mui/material/styles';
import MuiInputLabel from '@mui/material/InputLabel';

const InputLabel = styled(MuiInputLabel)(({ theme }) => (
    {
        color: "#196050",
        fontFamily: 'a_FuturicaBs',
        fontStyle: "normal",
        fontWeight: "200",
        lineHeight: "100%",
        WebkitTextStrokeWidth: "0.5px",
        WebkitTextStrokeColor: "#196050",
        '&.Mui-error': {
            color: "#EA405C",
            WebkitTextStrokeColor: "#EA405C",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "12px",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "16px",
        },
        
        '&.Mui-disabled': {
            color: "rgba(0, 0, 0, 0.38)",
            WebkitTextStrokeColor: "rgba(0, 0, 0, 0.38)",
        },

    }
)
);

export default InputLabel;