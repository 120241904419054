import { useEffect, useState } from "react";
import styles from "./NewsCard.module.scss";
import ViewsIcons from "../../assets/icons/views.svg";
import userStore from "../../store/userStore";
import { Checkbox } from "@mui/material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import newsStore from "../../store/newsStore";
import { formatDate, parseDate } from "../../utils";
import i18n from "../../i18n";
import { observer } from "mobx-react";
const t = i18n.t;

type NewsCardProps = {
  title: string;
  description: string;
  image: any;
  likes: number;
  createDate: string;
  views: number;
  id: number;
  is_liked: boolean;
};

const NewsCard = (props: NewsCardProps) => {
  return (
    <div className={styles.container}>
      <Link to={`/news/${props.id}/`} className={styles.link}>
        <img className={styles.image} src={props.image} alt="" />
        <h2 className={styles.title}>{props.title}</h2>
        <div className={styles.cardDescription}>
          <span>{props.description}</span>
        </div>
      </Link>

      <div className={styles.cardInfoContainer}>
        <div className={styles.cardInfoLeft}>
          <div className={styles.cardInfoLeftLikes}>
            <Checkbox
              onChange={() => {
                newsStore.like(props.id);
              }}
              icon={<FavoriteBorder />}
              checkedIcon={<Favorite />}
              sx={{ margin: 0, padding: 0 }}
              checked={props.is_liked}
              disabled={!userStore?.token || !userStore?.info}
            />

            <span>{props.likes}</span>
          </div>
          <div className={styles.cardInfoLeftViews}>
            <img src={ViewsIcons} alt="" />
            <span>{props.views}</span>
          </div>
        </div>
        <div className={styles.cardInfoRight}>
          <span>{formatDate(parseDate(props.createDate))}</span>
        </div>
      </div>
    </div>
  );
};

export default observer(NewsCard);
