import { useEffect, useState } from "react";

import userStore from "../../store/userStore";
import { ModalAuthorization } from "../Modal/ModalAuthorization";
import GenderWomen from "../../assets/icons/genderWomen.svg";
import GenderMan from "../../assets/icons/genderMan.svg";
import EditingProfile from "../../assets/icons/editingProfile.svg";
import { toast } from "react-toastify";
import { ModalProfileEditing } from "../Modal/ModalProfileEditing";
import { ModalLogout } from "../Modal/ModalLogout";
import { declination, initiativesName } from "../../utils";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import testStore from "../../store/testStore";

const HeroProfile = () => {
  const [authOpen, setAuthOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [tooltipShow, setTooltipShow] = useState(false);
  const [editingOpen, setEditingOpen] = useState(false);

  const initiativesNameVariable = initiativesName(userStore?.info?.name);
  const [finishedTests, setFinishedTests] = useState<any>();
  let { t } = useTranslation();
  useEffect(() => {
    (async () => {
      try {
        return await testStore.getFinishedTests();
        // return setFinishedTests(response);
      } catch (e) {
        console.error(`error: ${e}`);
      }
    })();
  }, []);
  return (
    <>
      {userStore.token ? (
        <div
          className="hero-functional__account"
          onMouseEnter={() => {
            setTooltipShow(true);
          }}
        >
          <div className="hero-functional__account-avatar">
            {/* TODO: Use this avatar component https://mui.com/material-ui/react-avatar/#main-content */}
            <span>{initiativesNameVariable}</span>
          </div>
          <div className="hero-functional__account-arrow">
            <i className="arrow top"></i>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setAuthOpen(() => true);
          }}
          className="hero-functional__account hero-functional__change-language"
        >
          {t("Log-in")}
        </div>
      )}
      {tooltipShow && (
        <div
          className="hero-tooltip__container"
          onMouseLeave={() => {
            setTooltipShow(false);
          }}
        >
          <div className="hero-tooltip-info__container">
            <div className="hero-tooltip-info-avatar__container">
              <div className="hero-tooltip-avatar">
                <span>{initiativesNameVariable}</span>
              </div>
            </div>
            <div className="hero-tooltip-info">
              <div className="hero-tooltip-title">
                <span>{userStore?.info?.name}</span>
                <div className="hero-tooltip__container-button">
                  <img
                    onClick={() => {
                      setEditingOpen(true);
                    }}
                    src={EditingProfile}
                    alt=""
                  />
                  <svg
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setLogoutModal(true);
                    }}
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_296_4276)">
                      <path
                        d="M10.6745 4.46116V2.75282C10.6745 2.29974 10.7089 1.70521 10.4654 1.38483C10.2219 1.06445 9.72045 1.04448 9.3761 1.04448H3.14381C2.79945 1.04448 2.27635 1.06445 2.03286 1.38483C1.78936 1.70521 1.84541 2.29974 1.84541 2.75282V13.0029C1.84541 13.4559 1.78936 14.0784 2.03286 14.3988C2.27635 14.7192 2.79945 14.7112 3.14381 14.7112H9.3761C9.72045 14.7112 10.2219 14.7192 10.4654 14.3988C10.7089 14.0784 10.6745 13.4559 10.6745 13.0029V11.2945M12.7519 5.48523L15.3487 7.87784M15.3487 7.87784L12.7519 10.3194M15.3487 7.87784H6.48717"
                        stroke="#356649"
                        strokeWidth="1.7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_296_4276">
                        <rect width="17" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className="hero-tooltip-age">
                {userStore?.info?.sex === 0 && (
                  <span className="hero-tooltip-age__gender">
                    <img src={GenderMan} alt="GenderMan" />
                  </span>
                )}
                {userStore?.info?.sex === 1 && (
                  <span className="hero-tooltip-age__gender">
                    <img src={GenderWomen} alt="GenderWomen" />
                  </span>
                )}
                <span>
                  {userStore?.info?.age}
                  {declination(userStore?.info?.age, [
                    ` ${t("HeroProfile.year")}`,
                    ` ${t("HeroProfile.of the year")}`,
                    ` ${t("HeroProfile.years")}`,
                  ])}
                </span>
              </div>
              <div className="hero-tooltip-registration">
                <span>{userStore?.info?.home}</span>
              </div>
            </div>
          </div>

          <div className="hero-tooltip-testing__container">
            {testStore?.finishedTests.length > 0 && (
              <>
                <span>{t("Completed tests")}</span>
                {testStore?.finishedTests.map((test, index) => (
                  <>
                    <div className="hero-toltip-testing__test">
                      <div className="hero-toltip-testing__test-num">
                        <span>{index + 1}</span>
                      </div>
                      <div className="hero-toltip-testing__test-title">
                        <span>{test.test}</span>
                      </div>
                      <div className="hero-toltip-testing__test-result">
                        <span>
                          {test.score} {t("HeroProfile.score")}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      )}

      {userStore.info && userStore.token && (
        <>
          <ModalProfileEditing
            open={editingOpen}
            setOpen={(value) => setEditingOpen(() => value)}
          />
          <ModalLogout
            open={logoutModal}
            setOpen={(value) => setLogoutModal(() => value)}
          />
        </>
      )}

      <ModalAuthorization
        open={authOpen}
        setOpen={(value) => setAuthOpen(() => value)}
      />
    </>
  );
};

export default observer(HeroProfile);
