import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout } from "../components/layouts/Layout";

import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { ControlledBootstrapInput } from "../components/form/Input";
import { FormControl } from "@mui/material";
import GradientButton from "../components/Button/GradientButton";
import { restorePassword } from "../api/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    password: yup.string().min(8).max(100),
    password2: yup.string().min(8).max(100),
  })
  .required();

export default function PasswordRestore() {
  let { t } = useTranslation();
  let { uid, key } = useParams();

  let navigate = useNavigate();

  const {
    control,
    setError,
    watch,
    handleSubmit,
    reset,
    register,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      password2: "",
    },
  });

  const onSubmit = async (data: { password: string; password2: string }) => {
    try {
      let response = await restorePassword(uid, key, data.password);
      reset();
      toast.success(
        t(
          "Toast.PasswordRestore.Password changed successfully, you can login with new password"
        )
      );
      navigate("/");
    } catch (e) {
      // Get errors from backend and tell react hook form
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response.data) {
            Object.entries(e.response.data).forEach((entry) => {
              const [key, value] = entry;
              // @ts-ignore
              setError(key, value);
            });
            toast.error(t("Toast.ResetPassword.CheckData"));
          }
        } else {
          toast.error(t("Toast.ResetPassword.Error"));
        }
      } else {
        toast.error(t("Toast.ResetPassword.UnknownError"));
      }
    }
  };
  return (
    <Layout>
      <h1>Восстановление пароля</h1>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <ControlledBootstrapInput
          control={control}
          name="password"
          label="Введите новый пароль*"
          type="password"
        />
        <ControlledBootstrapInput
          control={control}
          name="password2"
          label="Повторите новый пароль*"
          type="password"
        />
        <FormControl variant="standard" margin="none">
          <div className="modalSuccessBtn">
            <GradientButton type="submit">Изменить пароль</GradientButton>
          </div>
        </FormControl>
      </form>
    </Layout>
  );
}
