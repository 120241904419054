import { useEffect, useState } from "react";
import { Section } from "../Section/Section";
import GradientButton from "../Button/GradientButton";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import image from "../../assets/image/hero.png";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import HeroChangeLaunguage from "./HeroChangeLaunguage";
import HeroProfile from "./HeroProfile";
import userStore from "../../store/userStore";
import { ModalAuthorization } from "../Modal/ModalAuthorization";
import { Trans, useTranslation } from "react-i18next";
import ModalProfile from "../Modal/ModalProfile";
import heroDesktop from "../../assets/image/heroDesktop.png";
import heroTablet from "../../assets/image/heroTablet.png";
import heroMobile from "../../assets/image/heroMobile.png";

const Hero = () => {
  let { t } = useTranslation();
  const location = useLocation();
  let { newsId, testId } = useParams();
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [redirectCase, setRedirectCase] = useState(false);

  // function redirectCaseFunction() {
  //     return redirectCase ? redirect("/tests");
  // }

  useEffect(() => {
    (async () => {
      runInAction(async () => {});
    })();
  }, []);

  return (
    <Section noContainer={true} className="hero">
      <div
        className={`hero-bg ${
          location.pathname === `/test/${testId}/` && "hero-bg-testingMobile"
        }`}
      >
        {/* <picture>
          <img src={image} alt="" />
        </picture> */}
        <picture>
          <source srcSet={heroDesktop} media="(min-width: 992px)" />
          <source
            srcSet={heroTablet}
            media="(min-width: 768px) and (max-width: 992px)"
          />
          <img src={heroMobile} alt="hero mobile" />
        </picture>
      </div>

      <div className="hero-container container">
        <div className="hero-title__container">
          <div className="hero-functional__container mobile">
            <ModalProfile />
            <HeroChangeLaunguage />
          </div>
          {/* <Link to={"/"}>
            <div className="hero-title__container-title">
              <span>{t("InfoSite.Circassian")}</span>
              <span>{t("InfoSite.Deer")}</span>
            </div>
          </Link>
          {location.pathname === `/test/${testId}/` ? (
            <div className="hero-title__container-description mobile">
              <span>{t("InfoSite.info")}</span>
            </div>
          ) : (
            <div className="hero-title__container-description">
              <span>{t("InfoSite.info")}</span>
            </div>
          )} */}
        </div>
        <div className="hero-functional">
          <div className="hero-functional__container">
            <HeroProfile />
            <HeroChangeLaunguage />
          </div>
          {(location.pathname === "/" ||
            location.pathname === "/news/" ||
            location.pathname === `/news/${newsId}/`) && (
            <>
              {userStore.token === undefined ? (
                <>
                  <div
                    className="hero-functional__btn"
                    onClick={() => {
                      setOpenAuthModal(true);
                      setRedirectCase(true);
                    }}
                  >
                    <GradientButton>{t("hero.startTesting")}</GradientButton>
                  </div>
                </>
              ) : (
                <div className="hero-functional__btn">
                  <NavLink to={"/tests"}>
                    <GradientButton>{t("hero.startTesting")}</GradientButton>
                  </NavLink>
                </div>
              )}
            </>
          )}
          {/* {location.pathname === "/tests" && (
            <div className="hero-functional__text">
              <h2>{t("hero.chooseAtest")}</h2>
            </div>
          )}
          {location.pathname === `/test/${testId}/` && (
            <div className="hero-functional__text">
              <h3>
                <Trans i18nKey="Hero">
                  testing <br /> began
                </Trans>
                ...
              </h3>
            </div>
          )} */}
        </div>
      </div>
      <ModalAuthorization
        open={openAuthModal}
        setOpen={(value) => setOpenAuthModal(value)}
        redirectCase={redirectCase}
      />
    </Section>
  );
};

export default observer(Hero);
