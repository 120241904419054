import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { NoMatch } from './pages/NoMatch';
import TestPage from './pages/TestPage';
import UIPage from './pages/UI';
import { TestIndex } from './pages/TestIndex';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import userStore from './store/userStore';
import PasswordRestore from './pages/PasswordRestore';
import ConfirmRegistration from './pages/ConfirmRegistration';
import NewsPage from './pages/NewsPage';
import SingleNews from './pages/SingleNews';
import ModalSocial from './components/Modal/ModalSocial';
import GlobalModal from './components/Modal/GlobalModal';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path={"/ui"} element={<UIPage />} />
          <Route path={"/news/"} element={<NewsPage />} />
          <Route path={"/news/:newsId/"} element={<SingleNews />} />
          <Route path={"/tests"} element={
            <ProtectedRoute>
              <TestIndex />
            </ProtectedRoute>
          } />

          <Route path={"/test/:testId/"} element={
            <ProtectedRoute>
              <TestPage />
            </ProtectedRoute>
          } />
          <Route path={"/restore/:uid/:key/"} element={
            <PasswordRestore />
          } />
          <Route path={"/activate/:uid/:key/"} element={
            <ConfirmRegistration />
          } />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <ModalSocial/>
        <GlobalModal/>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;


const ProtectedRoute = ({ children }) => {

  if (!userStore.token) {
    return <Navigate to="/" replace />;
  }

  return children;
};