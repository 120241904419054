import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl, FormControlLabel } from "@mui/material";
import BootstrapInput, { ControlledBootstrapInput } from "../form/Input";
import InputLabel from "../form/InputLabel";
import Modal from "./Modal";
import Cross from "../../assets/icons/cross.svg";
import userStore from "../../store/userStore";
import { ModalProps } from "../../interface/interface";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ControlledSelect } from "../form/Select";
import { ControlledDatePicker } from "../form/DatePicker";
import CustomCheckbox from "../form/Checkbox";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateCurrentUser } from "../../api/user";
import { formatDate, parseDate } from "../../utils";
import axios from "axios";
import { ErrorContainer } from "./ModalRegistration";

export function ModalProfileEditing(props: ModalProps) {
  let { t } = useTranslation();

  const handleOpen = () => props.setOpen(true);
  const handleClose = () => props.setOpen(false);

  const schema = yup
    .object({
      name: yup.string().required(t("ModalError.Enter your name")),
      password: yup.string().min(8).max(100),
      password2: yup.string().min(8).max(100),
      // age: yup.number().positive().integer().required(),
      email: yup
        .string()
        .required(t("ModalError.Enter email"))
        .email(t("ModalError.Email entered incorrectly")),
      mailing: yup.boolean(),
      form_after_test: yup.boolean(),
      part_in_contest: yup.boolean(),
    })
    .required();

  const {
    control,
    setError,
    watch,
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: userStore.info.name,
      sex: userStore.info.sex,
      home: userStore.info.home,
      birthday: userStore.info.birthday,
      email: userStore.info.email,
      mailing: userStore.info.mailing,
      form_after_test: userStore.info.form_after_test,
      part_in_contest: userStore.info.part_in_contest,
    },
  });
  
  const onSubmit = async (data) => {
    // Username is the same as email
    console.log(data);
    // Format birthday
    try {
      data.birthday = formatDate(parseDate(data.birthday));
    } catch (e) {
      setError("birthday", { message: t("ModalError.Invalid date") });
      return;
    }

    try {
      let result = await updateCurrentUser(data);
      let newInfo = await userStore.getUserInfo();
      // Set values to new values
      setValue("name", result.name);
      setValue("sex", result.sex);
      setValue("home", result.home);
      setValue("birthday", result.birthday);
      setValue("email", result.email);
      setValue("mailing", result.mailing);
      setValue("form_after_test", result.form_after_test);
      setValue("part_in_contest", result.part_in_contest);
      handleClose();
    } catch (e) {
      // Get errors from backend and tell react hook form
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response.data) {
            Object.entries(e.response.data).forEach((entry) => {
              const [key, value]: [string, Array<string>] = entry as [
                string,
                string[]
              ];

              setError(key as any, {
                message: value.join(","),
              });
            });
            toast.error(t("Toast.ResetPassword.CheckData"));
          }
        } else {
          toast.error(t("Toast.ResetPassword.Error"));
        }
      } else {
        toast.error(t("Toast.ResetPassword.UnknownError"));
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box
          className="modalAuthorization modalProfileEditing modal"
          sx={{
            overflowY: "auto",
            maxHeight: "100%",
          }}
        >
          <div className="modal-title-cross">
            <h2 className="modal-title" id="transition-modal-title">
              {t("ModalProfileEditing.title")}
            </h2>
            <img
              className="modal-cross"
              onClick={handleClose}
              src={Cross}
              alt=""
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modalInputs">
              <ControlledBootstrapInput
                control={control}
                name="name"
                label={t("form.label.name")}
                fullWidth
              />
              <ControlledBootstrapInput
                control={control}
                name="email"
                type="email"
                label={t("form.label.Email*")}
                fullWidth
              />
              <ControlledSelect
                control={control}
                name="sex"
                label={t("form.label.sex")}
                fullWidth
                options={[
                  {
                    name: t("form.selectOption.male"),
                    value: 0,
                  },
                  {
                    name: t("form.selectOption.female"),
                    value: 1,
                  },
                  {
                    name: t("form.selectOption.unspecified"),
                    value: 2,
                  },
                ]}
              />
              <div className="ModalEditingDataInputDate">
                <ControlledDatePicker
                  control={control}
                  name="birthday"
                  label={t("form.label.birthday")}
                  fullWidth
                />
              </div>
              <ControlledBootstrapInput
                control={control}
                name="home"
                label={t("form.label.home")}
                fullWidth
              />
              <Controller
                name="form_after_test"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState }) => (
                  <FormControlLabel
                    sx={{
                      span: {
                        fontWeight: {
                          xs: 200,
                        },
                        fontSize: {
                          xs: 12,
                        },
                        letterSpacing: {
                          xs: "-0.02em",
                        },
                        color: "#196050",
                        lineHeight: {
                          xs: "18px",
                          md: "20px",
                        },
                      },
                    }}
                    control={<CustomCheckbox checked={field.value} />}
                    label={t("form.label.socialSurveyQuestionnaire")}
                    labelPlacement="end"
                    {...field}
                  />
                )}
              />
              <Controller
                name="mailing"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState }) => (
                  <FormControlLabel
                    sx={{
                      span: {
                        fontWeight: {
                          xs: 200,
                        },
                        fontSize: {
                          xs: 12,
                        },
                        letterSpacing: {
                          xs: "-0.02em",
                        },
                        color: "#196050",
                        lineHeight: {
                          xs: "18px",
                          md: "20px",
                        },
                      },
                    }}
                    control={<CustomCheckbox checked={field.value} />}
                    label={t("form.label.newsLetter")}
                    labelPlacement="end"
                    {...field}
                  />
                )}
              />

              <Controller
                name="part_in_contest"
                control={control}
                rules={{
                  required: false,
                }}
                render={({ field, fieldState }) => (
                  <FormControlLabel
                    sx={{
                      span: {
                        fontWeight: {
                          xs: 200,
                        },
                        fontSize: {
                          xs: 12,
                        },
                        letterSpacing: {
                          xs: "-0.02em",
                        },
                        color: "#196050",
                        lineHeight: {
                          xs: "18px",
                          md: "20px",
                        },
                      },
                    }}
                    control={<CustomCheckbox checked={field.value} />}
                    label={t("form.label.takePart")}
                    labelPlacement="end"
                    {...field}
                  />
                )}
              />
              {errors && Object.entries(errors).length > 0 && (
                <ErrorContainer>
                  {Object.entries(errors).map(([key, value]) => (
                    <div>{value.message}</div>
                  ))}
                </ErrorContainer>
              )}
            </div>
            <FormControl variant="standard" margin="none" fullWidth>
              <div className="modalSuccessBtn">
                <GradientButton type="submit" fullWidth>
                  {t("form.button.save")}
                </GradientButton>
              </div>
            </FormControl>
          </form>
        </Box>
      </Fade>
    </Modal>
  );
}
