import React, { useState } from 'react'
import testStore, { AnswerUploadFile } from '../../store/testStore'
import { observer } from 'mobx-react-lite';
import FileUpload from '../FileUpload';
import i18n from "../../i18n";
import { QuestionUploadFile } from '../../api/tests';
const t = i18n.t;

interface QuestionUploadInputProps {
  question: QuestionUploadFile
  answer?: AnswerUploadFile
}


function QuestionUploadInput({ question, answer }: QuestionUploadInputProps) {

  let lastAnswer: AnswerUploadFile| undefined = answer

  function updateAnswer(file: File) {
    let answer: AnswerUploadFile = {
        id: question.id,
        question_type: question.question_type,
        flagged: false,
        file: file,
    }


    testStore.setAnswer(answer)
}


  return (
    <div>
      <FileUpload label={"lol"} updateFilesCb={()=>""} updateAnswer={updateAnswer} answer={answer} multiple={false}/>
    </div>
  )
}


export default observer(QuestionUploadInput);