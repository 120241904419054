type BannerItemProps = {
  image: string;
  title: string;
  description: string;
};

export const BannerItem = (props: BannerItemProps) => {
  return (
    <>
      <div className="bannerFilter"></div>
      <div className="bannerBg">
        <img src={props.image} alt="" />
      </div>
      <div className="bannerItem">
        <div className="bannerItem__padding">
          <div className="bannerItem__item">
            <h2>{props.title}</h2>
          </div>
          <div className="bannerItem__description">
            <span>{props.description}</span>
          </div>
        </div>
      </div>
    </>
  );
};
