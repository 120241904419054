import { styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';

// Inspired by blueprintjs
function CustomCheckbox(props: CheckboxProps) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        // disableRipple
        color="primary"
        // checkedIcon=     {<BpCheckedIcon />}
        // icon={<BpIcon />}
        // inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

export default CustomCheckbox;