import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl, Typography } from "@mui/material";
import Modal from "./Modal";
import FlatButton from "../Button/FlatButton";
import { ModalPromptProps, ModalTextProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import modalStore from "../../store/modalStore";


export function DialogModal(props: ModalPromptProps) {
  let { t } = useTranslation();

  const handleClose = () => {
    if (props.keepOpenOnBackgroundClick){
    }else {
        modalStore.hideModal();
        props.onNo();
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className="modalFinaly modal">
            <h2 className="modal-title" id="transition-modal-title">
              {props.title}
            </h2>
            <Typography
              className="modal-description"
              id="transition-modal-description"
            >
              {props.description}
            </Typography>
            <div className="modalFinalyBtn__container">
              <FormControl variant="standard" margin="none">
                <div className="modalFinalyBtn">
                  <FlatButton onClick={() => {
                    props.onNo && props.onNo()
                    props.onClose && props.onClose()
                    modalStore.hideModal();
                }} fullWidth>
                    {props.cancelText || t("ModalFinishTesting.button.cancel")}
                  </FlatButton>
                </div>
              </FormControl>
              <FormControl variant="standard" margin="none">
                <div className="modalFinalyBtn">
                  <GradientButton
                    onClick={() => {
                      props.onYes();
                      props.onClose && props.onClose();
                      modalStore.hideModal();
                    }}
                    fullWidth
                  >
                    {props.okText || t("ModalCancelTest.button.Go")}
                  </GradientButton>
                </div>
              </FormControl>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
