import styles from "./CardTest.module.scss";
import { Arrow } from "../Arrow/Arrow";
import { useTranslation } from "react-i18next";
import { TestStatusType } from "../../api/tests";

type CardTestProps = {
  title: string;
  description: string;
  image: any;
  url: string;
  status: TestStatusType;
  score: number;
};

export const CardTest = (props: CardTestProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <img className={styles.image} src={props.image} alt="" />
      <h2 className={styles.title}>{props.title}</h2>
      <div className={styles.info}>
        <div className={styles.description}>
          <span>{props.description}</span>
        </div>
        <div className={styles.arrow}>
          {props.status === TestStatusType.Сonfirmed && (
            <span className={styles.score}>
              {props.score} {t("HeroProfile.scores")}
            </span>
          )}
          {props.status === TestStatusType.NotPassed && (
            <Arrow link={props.url} direction="right" />
          )}
          {props.status === TestStatusType.NotFinished && (
            <Arrow link={props.url} direction="right" />
          )}
          {props.status === TestStatusType.Moderation && (
            <Arrow disable={true} direction="right" />
          )}
        </div>
      </div>
    </div>
  );
};
