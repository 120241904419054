import Banner from "../components/Banner/Banner";
import { Layout } from "../components/layouts/Layout";
import ModalSelectLanguage from "../components/Modal/ModalSelectLanguage";
import News from "../components/News/News";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import About from "../components/About/About";

export function Home() {
  let { t } = useTranslation();
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.Title.Home")}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div>
        <Banner />
        <About />
        <News />
      </div>
      <ModalSelectLanguage
        title={t("selectLanguage.title.Choose a language platform")}
        description=""
      />
    </Layout>
  );
}
