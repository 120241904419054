import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { ModalCancelTest } from "../Modal/ModalCancelTest";
import styles from "./Breadcrumbs.module.scss";

type BreadcrumbsProps = {
  nested?: boolean;
  textNested?: string;
  textNestedEnd?: string;
  link?: string;
  testing?: boolean;
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  let { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState("");

  return (
    <div className={styles.breadcrumbsContainer}>
      <span className={`${styles.breadcrumbs} ${styles.active}`}>
        {props.testing ? (
          <span
            className={`${styles.breadcrumbs} ${styles.active}`}
            onClick={() => {
              setRedirectLink("/");
              setShowModal(true);
            }}
          >
            {t("Breadcrumbs.Home")}
          </span>
        ) : (
          <NavLink to={"/"}>{t("Breadcrumbs.Home")}</NavLink>
        )}

        <i className="arrow right"></i>
      </span>

      {props.nested ? (
        <>
          <span
            // onClick={() => {
            //   if (props.testing) {
            //     setShowModal(true);
            //   }
            // }}
            className={`${styles.breadcrumbs} ${styles.active} ${styles.breadcrumbsMargin}`}
          >
            {props.testing ? (
              <span
                onClick={() => {
                  setRedirectLink(props.link);
                  setShowModal(true);
                }}
              >
                {props.textNested}
              </span>
            ) : (
              <Link to={props.link}>{props.textNested}</Link>
            )}

            <i className={`arrow right ${styles.arrowMobileNone} `}></i>
          </span>
          <span
            className={`${styles.breadcrumbs} ${styles.mobileSpan} ${styles.breadcrumbsMargin} ${styles.breadcrumbsMobileTransform}`}
          >
            <span className={styles.mobileSpan}>{props.textNestedEnd}</span>
          </span>
        </>
      ) : (
        <>
          <span
            className={`${styles.breadcrumbs}  ${styles.breadcrumbsMargin} ${styles.breadcrumbsMobileTransform}`}
          >
            <a>{props.textNested}</a>
          </span>
        </>
      )}
      <ModalCancelTest
        title={t("ModalCancelTest.title.Cancel a test?")}
        description={t(
          "ModalCancelTest.title.Returning to the previous page will invalidate your answers"
        )}
        open={showModal}
        setOpen={setShowModal}
        navigateLink={redirectLink}
      />
    </div>
  );
};
