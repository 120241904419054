import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#FFFFFF",
      color: '#356649',
      fontFamily: 'a_FuturicaBs',
      fontStyle: "normal",
      fontWeight: 200,
      borderRadius: "5px",
      fontSize: "12px",
      lineHeight: "16px",
    },
    [`& .${tooltipClasses.arrow}`] :{
        color: "white"
    }
  }));

export default CustomTooltip;