import { observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import { Layout } from "../components/layouts/Layout";
import newsStore from "../store/newsStore";
import userStore from "../store/userStore";
import { formatDate, parseDate } from "../utils";
import ViewsIcon from "../assets/icons/views.svg";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import Favorite from "@mui/icons-material/Favorite";
import { Checkbox } from "@mui/material";

const SingleNews = () => {
  let { t } = useTranslation();
  let { newsId } = useParams();
  useEffect(() => {
    (async () => {
      await newsStore.getSingleNews(Number(newsId));
    })();
  }, [userStore.language]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${newsStore?.singleNews?.title || ""}`}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Breadcrumbs
        link={"/news/"}
        nested={true}
        textNested={t("Breadcrumbs.News")}
        textNestedEnd={newsStore?.singleNews?.title}
      />
      <div className="singleNewsContainer">
        {/* <div className="singleNewsImage">
          <img src={newsStore?.singleNews?.media} alt="" />
        </div> */}
        <div className="singleNewsTitle">
          <h2>{newsStore?.singleNews?.title}</h2>
        </div>
        <div className="singleNewsDescription">
          <span>{newsStore?.singleNews?.description}</span>
        </div>
        <div
          className="singleNewsDescription"
          dangerouslySetInnerHTML={{ __html: newsStore?.singleNews?.content }}
        ></div>
        <div className="singleNewsInfo">
          <div className="singleInfoNews">
            {/* <div className="singleNewsLikes">
              <Checkbox
                onChange={() => {
                  newsStore.like(Number(newsId));
                }}
                icon={<FavoriteBorder />}
                checkedIcon={<Favorite />}
                sx={{ margin: 0, padding: 0 }}
                checked={newsStore?.singleNews?.is_liked}
                disabled={!userStore.token}
              />
              <span>{newsStore?.singleNews?.likes_count}</span>
            </div> */}
            <div className="singleNewsViews">
              <img src={ViewsIcon} alt="" />
              <span>{newsStore?.singleNews?.views}</span>
            </div>
          </div>
          <div className="singleInfoDate">
            <span>
              {newsStore?.singleNews?.created_at &&
                formatDate(parseDate(newsStore?.singleNews?.created_at))}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default observer(SingleNews);
