import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl } from "@mui/material";
import BootstrapInput from "../form/Input";
import InputLabel from "../form/InputLabel";
import Modal from "./Modal";
import Cross from "../../assets/icons/cross.svg";
import userStore from "../../store/userStore";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import FlatButton from "../Button/FlatButton";
import { ModalRegistration } from "./ModalRegistration";
import { useState } from "react";
import { ModalResetPassword } from "./ModalResetPassword";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    username: yup.string().required("Введите имя пользователя"),
    password: yup.string().required("Введите пароль"),
  })
  .required();

interface ModalAuthorizationProps {
  open?: boolean;
  setOpen?: (a: boolean) => void;
  redirectCase?: boolean;
}

export function ModalAuthorization(props: ModalAuthorizationProps) {
  let { t } = useTranslation();
  const [openModalReg, setOpenModalReg] = useState(false);
  const [openModalResetPass, setOpenModalResetPass] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => props.setOpen(false);

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const onSubmit = async (data) => {
    console.log(data);
    try {
      await userStore.login(data.username, data.password);
      handleClose();
      toast.success(t("Toast.Auth.You logged in successfully"));
      props.redirectCase && navigate("/tests");
    } catch (e: any) {
      toast.error(t("Toast.Auth.Failed to log in"));
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className="modalAuthorization modal">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-title-cross">
                <h2 className="modal-title" id="transition-modal-title">
                  {t("ModalAuthorization.title")}
                </h2>
                <img
                  className="modal-cross"
                  onClick={handleClose}
                  src={Cross}
                  alt=""
                />
              </div>
              <div className="modalInputs">
                <FormControl variant="standard" margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {t("form.label.Email*")}
                  </InputLabel>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <BootstrapInput
                        fullWidth
                        placeholder="example@gmail.com"
                        {...field}
                      />
                    )}
                  />
                </FormControl>
                <FormControl variant="standard" margin="normal" fullWidth>
                  <InputLabel shrink htmlFor="bootstrap-input">
                    {t("form.label.password")}
                  </InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <BootstrapInput
                        type="password"
                        fullWidth
                        placeholder={t("form.placeholder.password")}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </div>
              <FormControl variant="standard" fullWidth>
                <div className="modalSuccessBtn">
                  <GradientButton type="submit" fullWidth>
                    {t("ModalAuthorization.LoginToAccount")}
                  </GradientButton>
                </div>
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <div className="modalSuccessBtn">
                  <FlatButton
                    onClick={() => {
                      setOpenModalReg(true);
                    }}
                    fullWidth
                  >
                    {t("ModalAuthorization.Register")}
                  </FlatButton>
                </div>
              </FormControl>
              <div
                className="modalLogoutButtonResetPassword"
                onClick={() => {
                  setOpenModalResetPass(true);
                }}
              >
                <span>{t("ModalAuthorization.ForgotPassword")}</span>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
      <ModalRegistration open={openModalReg} setOpen={setOpenModalReg} />
      <ModalResetPassword
        open={openModalResetPass}
        setOpen={setOpenModalResetPass}
      />
    </div>
  );
}
