import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, Autoplay } from "swiper";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Section } from "../Section/Section";
import { BannerItem } from "./BannerItem";
import { useEffect } from "react";
import bannerStore from "../../store/bannerStore";
import { observer } from "mobx-react";
import userStore from "../../store/userStore";
import { useTranslation } from "react-i18next";

const Banner = () => {

  return (
    <Section
      noContainer
      style={{
        transform: "translate(0px, -20px)",
      }}
    >
      <div className="banner-container">
        <Swiper
          modules={[Pagination, Scrollbar, Autoplay]}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          loop={false}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          className="swiper-bunner"
        >
          {bannerStore.banner.map((item, index) => (
            <SwiperSlide key={index}>
              <BannerItem
                title={item.title}
                description={item.description}
                image={item.media}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Section>
  );
};

export default observer(Banner);
