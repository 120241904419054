import { Section } from "../Section/Section";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperInstance, { Navigation, Scrollbar, A11y } from "swiper";
import styles from "./News.module.scss";
import { Arrow } from "../Arrow/Arrow";
import { useEffect, useState } from "react";
import newsStore from "../../store/newsStore";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import userStore from "../../store/userStore";
import NewsCard from "./NewsCard";

const News = () => {
  let { t } = useTranslation();
  const [swiper, setSwiper] = useState<SwiperInstance | null>(null);
  const slideNext = () => swiper?.slideNext();
  const slidePrev = () => swiper?.slidePrev();

  return (
    <>
      {newsStore.news.length > 0 ? (
        <Section noContainer>
          <div className={styles.title}>
            <Link to="/news/">
              <h2>{t("News")}</h2>
            </Link>
          </div>
          <div className={styles.sliderContainer}>
            {newsStore.news.length >= 4 && (
              <>
                <div className={styles.sliderArrowLeft} onClick={slidePrev}>
                  <Arrow direction="left" />
                </div>
                <div className={styles.sliderArrowRight} onClick={slideNext}>
                  <Arrow direction="right" />
                </div>
              </>
            )}

            <Swiper
              modules={[Navigation, Scrollbar, A11y]}
              className="swiper-news"
              slidesPerView={3}
              breakpoints={{
                0: {
                  width: 0,
                  slidesPerView: 1,
                  enabled: false,
                },
                768: {
                  width: 768,
                  slidesPerView: 2,
                },
                1200: {
                  width: 1200,
                  slidesPerView: 3,
                },
              }}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              onSwiper={(swiper) => setSwiper(swiper)}
            >
              <div className={styles.container}>
                {newsStore.news.map((item, index) => (
                  <SwiperSlide key={index}>
                    <NewsCard
                      title={item.title}
                      description={item.description}
                      image={item.media}
                      views={Number(item.views)}
                      createDate={item.created_at}
                      likes={item.likes_count}
                      id={item.id}
                      is_liked={item.is_liked}
                    />
                  </SwiperSlide>
                ))}
              </div>
            </Swiper>
          </div>
        </Section>
      ) : (
        <div style={{ marginBottom: 50 }}></div>
      )}
    </>
  );
};

export default observer(News);
