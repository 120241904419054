import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl } from "@mui/material";
import BootstrapInput from "../form/Input";
import InputLabel from "../form/InputLabel";
import Modal from "./Modal";
import Cross from "../../assets/icons/cross.svg";
import { useForm, Controller } from "react-hook-form";
import { ModalProps } from "../../interface/interface";
import i18n from "../../i18n";
import { makeRestoreLink } from "../../api/user";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

export function ModalResetPassword(props: ModalProps) {
  const handleClose = () => props.setOpen(false);
  let { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      email: "",
    },
  });
  let navigate = useNavigate();
  const onSubmit = async (data: { email: string }) => {
    try {
      let response = await makeRestoreLink(data.email);
      reset();

      toast.success(t("Toast.ResetPassword.LinkSentToYourEmail"));
      navigate("/");
    } catch (e) {
      // Get errors from backend and tell react hook form
      if (axios.isAxiosError(e)) {
        if (e.response) {
          if (e.response.data) {
            Object.entries(e.response.data).forEach((entry) => {
              const [key, value] = entry;
              // @ts-ignore
              setError(key, value);
            });
            toast.error(t("Toast.ResetPassword.CheckData"));
          }
        } else {
          toast.error(t("Toast.ResetPassword.Error"));
        }
      } else {
        toast.error(t("Toast.ResetPassword.UnknownError"));
      }
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className="ModalResetPassword modal">
            <div className="modal-title-cross">
              <h2 className="modal-title" id="transition-modal-title">
                {t("ModalResetPassword.title")}
              </h2>
              <img
                className="modal-cross"
                onClick={handleClose}
                src={Cross}
                alt=""
              />
            </div>

            <div className="ModalResetPasswordDescription">
              <span>{t("ModalResetPassword.description")}</span>
            </div>
            <div className="modalInputs">
              <FormControl variant="standard" fullWidth>
                <InputLabel shrink htmlFor="bootstrap-input">
                  {t("form.label.Email*")}
                </InputLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <BootstrapInput
                      fullWidth
                      placeholder="example@gmail.com"
                      {...field}
                    />
                  )}
                />
              </FormControl>
            </div>
            <FormControl variant="standard" margin="none" fullWidth>
              <div className="modalSuccessBtn">
                <GradientButton type="submit" onClick={handleClose} fullWidth>
                  {t("form.button.Send")}
                </GradientButton>
              </div>
            </FormControl>
          </Box>
        </form>
      </Fade>
    </Modal>
  );
}
