import { FormControl } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Arrow } from '../components/Arrow/Arrow'
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs'
import FlatButton from '../components/Button/FlatButton'
import GradientButton from '../components/Button/GradientButton'

import CustomCheckbox from '../components/form/Checkbox'
import DatePicker from '../components/form/DatePicker'
import { Slider } from '../components/form/DiscreteSlider'
import BootstrapInput from '../components/form/Input'
import InputLabel from '../components/form/InputLabel'
import { Layout } from '../components/layouts/Layout'
import { ModalAuthorization } from '../components/Modal/ModalAuthorization'
import { ModalFinishTesting } from '../components/Modal/ModalFinishTesting'
import { ModalProfileEditing } from '../components/Modal/ModalProfileEditing'
import { ModalSuccess } from '../components/Modal/ModalSuccess'
import { PlayVideo } from '../components/PlayVideo/PlayVideo'
import LinearProgress from '../components/Progress'
import { Section } from '../components/Section/Section'
import modalStore from '../store/modalStore'
import socialStore from '../store/socialStore'

export default function UIPage() {

    return (
        <Layout>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Страница теста UI</title>
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>

            <Section className='lol'>
                <DatePicker value='lol' />

                <h1 style={{
                    fontFamily: "Casual Contact MF",
                    fontWeight: "400"
                }}>ЧЕРКЕССКИЙ</h1>
                <h1 style={{
                    fontFamily: "Casual Contact MF",
                    fontWeight: "400"
                }}>ОЛЕНЕНОК</h1>

                <FlatButton onClick={()=>{socialStore.show()}}>Show Social Quiz</FlatButton>
                <FlatButton onClick={()=>{modalStore.alertModal({
                    description: "lol",
                    title: "lols",
                    onClose: ()=> { 
                        console.log("win")
                        modalStore.alertModal({
                            description: "lol",
                            title: "lols",
                            onClose: ()=> { 
        
                                
                            }
                        })

                    }
                })}}>Show Success modal</FlatButton>
                <FlatButton onClick={()=>{modalStore.dialogModal({
                    description: "lol",
                    title: "lols",
                    onYes: ()=>{ 
                        modalStore.alertModal({
                            description: "yes",
                            title: "yes",
                            onClose: ()=> {}
                        })
                    },
                    onNo: () => {
                        modalStore.alertModal({
                            description: "no",
                            title: "no",
                            onClose: ()=> {}
                        })
                    },
                    keepOpenOnBackgroundClick: true,
                    okText:"Ok",
                    cancelText: "cancel"
                    
                })}}>Show dialog modal</FlatButton>
                <div>
                    <h1>Gradient button</h1>
                    <GradientButton>Default</GradientButton>
                    <FlatButton>Default</FlatButton>
                    <GradientButton disabled>Disabled</GradientButton>
                </div>
                <div>
                    <h1>Checkboxes</h1>
                    <CustomCheckbox ></CustomCheckbox>
                    <CustomCheckbox ></CustomCheckbox>
                </div>
                <div>
                    <h1>Tooltips</h1>
                    {/* <CustomTooltip title="Вверху" >
                <GradientButton>Default</GradientButton>
            </CustomTooltip> */}

                    <CustomCheckbox ></CustomCheckbox>
                    <CustomCheckbox ></CustomCheckbox>
                </div>
                <div>
                    <FormControl variant="standard" margin="normal">
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Имя и Фамилия
                        </InputLabel>
                        <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" placeholder='Ваше имя и фамилия' />
                    </FormControl>
                    <FormControl variant="standard" margin="normal" error>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Имя и Фамилия
                        </InputLabel>
                        <BootstrapInput helperText="lol" defaultValue="Имя" id="bootstrap-input" placeholder='Ваше имя и фамилия' error />
                    </FormControl>
                    <FormControl variant="standard" margin="normal">
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Имя и Фамилия
                        </InputLabel>
                        <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" placeholder='Ваше имя и фамилия' />
                    </FormControl>
                    <FormControl variant="standard" margin="normal" disabled>
                        <InputLabel shrink htmlFor="bootstrap-input">
                            Имя и Фамилия
                        </InputLabel>
                        <BootstrapInput defaultValue="react-bootstrap" id="bootstrap-input" placeholder='Ваше имя и фамилия' />
                    </FormControl>
                </div>
                <div>
                    <ModalOne />
                </div>
                <ModalSuccess title="Спасибо за Вашу активность" description='Вы помогаете нам стать лучше!' />
                <ModalSuccess title="Тестирование завершено успешно!" description='Результаты будут направлены Вам на элетронную почту' />
                <ModalAuthorization />
                <ModalFinishTesting title="Отменить прохождение теста?" description='Возвращение на предыдущую страницу аннулирует ваши ответы' />
                <ModalFinishTesting title="Завершить тестирование?" description='Вы не сможете в дальнейшем изменить Ваши ответы' />
                <ModalProfileEditing />
                <Breadcrumbs></Breadcrumbs>
                <Arrow direction="right" />
                <Arrow direction="left" />

                <LinearProgress variant="determinate" value={50} />
                <PlayVideo />

                <Slider aria-label="Temperature"
                    defaultValue={30}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={
                        [...Array(11).keys()].map(x => x++).map(i =>({
                            value: i,
                            label: i.toString()
                        }))
                    }
                    min={1}
                    max={10} />
            </Section>
        </Layout>
    )
}

function ModalOne() {
    const [modalOpen, setModalOpen] = React.useState(false);
    return <div>

    </div>
}