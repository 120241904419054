import styled from "styled-components";

export const FileUploadContainer = styled.section`
  position: relative;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 10px 0 15px;
  padding: 35px 0px;
  flex-direction: column;
  gap: 15px;
  button {
    padding: 14px 0px;
  }
  @media (min-width: 767px) {
    margin: 15px 0 15px;
    padding: 55px 0px;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    button {
      padding: 25px 30px;
    }
  }
`;

export const DropZoneContainer = styled.section<{ hasFiles?: boolean }>`
  position: relative;
  min-height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => (!props.hasFiles ? "none" : "2px dashed #E0E5F2")};
  background-color: ${(props) => (!props.hasFiles ? "white" : "transparent")};
  border-radius: 16px;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.03));
  text-align: center;
  p {
    margin: 0;
    padding: 0;
    font-weight: 200;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: rgba(0, 0, 0, 0.3);
  }

  .dragtext {
    font-style: normal;
    font-weight: 200;
    color: rgba(53, 102, 73, 0.9);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: rgba(53, 102, 73, 0.9);
    font-size: 16px;
  }
  @media (min-width: 767px) {
    p {
      font-size: 18px;
    }
  }
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

export const InputLabel = styled.label`
  position: absolute;

  font-size: 10px;
  letter-spacing: 0.05em;
  line-height: 28px;
  font-family: "a_FuturicaBs";
  font-style: normal;
  font-weight: 200;
  color: rgba(53, 102, 73, 0.9);
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: rgba(53, 102, 73, 0.9);

  @media (min-width: 767px) {
    font-size: 14px;
  }
`;

export const DragDropText = styled.p`
  font-weight: bold;
  letter-spacing: 2.2px;
  margin-top: 0;
  text-align: center;
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;

  span {
    font-size: 14px;
  }
`;

export const DocumentItem = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  @media (min-width: 767px) {
    padding: 0px 25px;
  }

  color: rgba(53, 102, 73, 0.9);

  & .progressbar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .filename {
    max-width: 200px;
    font-family: "a_FuturicaBs";
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */

    letter-spacing: 0.05em;

    color: rgba(53, 102, 73, 0.9);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: rgba(53, 102, 73, 0.9);
  }
  & .removeIcon {
    cursor: pointer;
  }
`;

export const DocumentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_91_1988)">
      <path
        d="M7.53906 0.93042H16.8018C17.2659 0.930501 17.7109 1.08165 18.0391 1.35062L24.5263 6.66693C24.8545 6.93582 25.039 7.30054 25.0391 7.68086V21.0082C25.0391 21.7689 24.6703 22.4985 24.0139 23.0364C23.3576 23.5743 22.4673 23.8765 21.5391 23.8765H7.53906C6.6108 23.8765 5.72057 23.5743 5.06419 23.0364C4.40781 22.4985 4.03906 21.7689 4.03906 21.0082V3.79868C4.03906 3.03797 4.40781 2.30841 5.06419 1.77051C5.72057 1.23261 6.6108 0.93042 7.53906 0.93042V0.93042ZM17.1641 3.08161V5.94987C17.1641 6.33022 17.3484 6.695 17.6766 6.96395C18.0048 7.2329 18.4499 7.384 18.9141 7.384H22.4141L17.1641 3.08161Z"
        fill="#237E6E"
      />
    </g>
    <defs>
      <clipPath id="clip0_91_1988">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(0.539062 0.93042)"
        />
      </clipPath>
    </defs>
  </svg>
);
