import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getTestIndex, TestInfo } from "../api/tests";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import { Layout } from "../components/layouts/Layout";
import { Section } from "../components/Section/Section";
import { CardTest } from "../components/SelectTest/CardTest";
import userStore from "../store/userStore";

const SelectTestContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    gap: "15px",
    marginTop: "30px",
    flexWrap: "wrap",
    paddingBottom: "30px",
  },
  [theme.breakpoints.up("md")]: {
    paddingBottom: "55px",
    gap: "19px",
  },
  [theme.breakpoints.up("lg")]: {
    paddingBottom: "146px",
  },
}));

export function TestIndex() {
  let { t } = useTranslation();
  const [tests, setTests] = useState<Array<TestInfo>>([]);
  useEffect(() => {
    (async () => {
      let response = await getTestIndex();
      setTests(response);
    })();
  }, [userStore.language]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.Title.Tests")}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Section noContainer style={{ width: "100%" }}>
        <Breadcrumbs
          nested={false}
          textNested={t("Breadcrumbs.TestSelection")}
        />
        <SelectTestContainer>
          {tests.map((test) => (
            <CardTest
              key={test.id}
              title={test.name}
              description={test.description}
              image={test.media}
              url={`/test/${test.id}/`}
              status={test.status}
              score={test.score}
            />
          ))}
        </SelectTestContainer>
      </Section>
    </Layout>
  );
}
