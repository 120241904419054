import { observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import { Layout } from "../components/layouts/Layout";
import NewsCard from "../components/News/NewsCard";

import newsStore from "../store/newsStore";
import userStore from "../store/userStore";

const NewsPage = () => {
  let { t } = useTranslation();
  useEffect(() => {
    (async () => {
      newsStore.getNews();
    })();
  }, [userStore.language, userStore.token]);
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("Helmet.Title.News")}</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <Breadcrumbs nested={false} textNested={t("Breadcrumbs.News")} />
      <div className="containerNewsPage">
        {newsStore.news.map((item, index) => (
          <NewsCard
            key={index}
            title={item.title}
            description={item.description}
            image={item.media}
            views={Number(item.views)}
            createDate={item.created_at}
            likes={item.likes_count}
            id={item.id}
            is_liked={item.is_liked}
          />
        ))}
      </div>
    </Layout>
  );
};

export default observer(NewsPage);
