import { t } from "i18next";
import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
  toJS,
} from "mobx";
import { create, persist } from "mobx-persist";
import { toast } from "react-toastify";
import {
  endAttempt,
  startAttempt,
  submitAnswer,
  submitAnswerFile,
  TestAttempt,
} from "../api/attempt";
import {
  finishedTests,
  getTest,
  IFinishedTests,
  QuestionType,
  TestInfo,
} from "../api/tests";
import { authorize, getCurrentUser } from "../api/user";
import image from "../assets/image/previewCardTest.jpg";
import modalStore from "./modalStore";
import socialStore from "./socialStore";
import userStore from "./userStore";

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000000;

enum TestState {
  Start,
  Test,
  Complete,
}

export interface AnswerBase {
  id: number;
  flagged: boolean;
}

export type AnswerSelect = AnswerBase & {
  question_type: QuestionType.Select;
  options: Array<number>;
};

export enum FileState {
  Uploading,
  Uploaded,
  Failed,
}

export type AnswerUploadFile = AnswerBase & {
  question_type: QuestionType.Upload;
  uploadProgress?: number;
  state?: FileState;
  /**
   * File to upload
   */
  file?: File;
  /**
   * Uploaded file url
   */
  fileUrl?: File;
  filename?: string;
  filesize?: number;
};

export type AnswerText = AnswerBase & {
  question_type: QuestionType.Text;
  text: string;
};

export type Answer = AnswerSelect | AnswerUploadFile | AnswerText;

export enum FileUploadState {
  PROCESSING,
  FAIL,
  SUCCESS,
}
export interface FileUploadObject {
  file: File;
  id: string;
  state: FileUploadState;
  progress: number;
  filename: string;
  size: number;
}

// Dumb state machine
class TestSession {
  /**
   * Used to block user from moving anywhere while the answer is being submitted
   */
  @observable loading?: Boolean = false;
  /**
   * Flag to say that current answer has been changed
   */
  @persist @observable answerHasChanges: boolean = false;
  @observable finishedTests: IFinishedTests[] = [];
  /**
   * Information about the current test
   */
  @persist("object") @observable info?: TestInfo = undefined;

  /**
   * Frontend state of the test
   */
  @persist @observable state: TestState = TestState.Start;

  /**
   * Server side attempt to attach answers to
   */
  @persist("object") @observable attempt?: TestAttempt = undefined;
  /**
   * Current question number
   */
  @persist @observable currentQuestionNumber: number = 0;

  /**
   * Local copy of all the answers
   */
  @persist("object") @observable answers: Array<Answer> = [];

  @action
  resetState() {
    this.info = undefined;
    this.answers = [];
    this.attempt = undefined;
    this.state = TestState.Start;
    this.currentQuestionNumber = 0;
    this.answerHasChanges = false;
  }

  @computed get currentQuestion() {
    return this.info?.questions[this.currentQuestionNumber];
  }
  @computed get totalQuestions() {
    if (!this.info || !this.info.questions) return 0;
    return this.info.questions.length;
  }

  @computed({
    context: [],
  })
  get currentAnswer(): Answer {
    // Here to trigger updates since mobx does not know than if answers changed we need a rerender
    let answers = this.answers;
    if (!this.currentQuestion) return undefined;
    return this.getAnswer(this.currentQuestion.id);
  }

  // For arrows to be disabled
  @computed get firstQuestion() {
    return this.currentQuestionNumber == 0;
  }
  @computed get lastQuestion() {
    return this.currentQuestionNumber >= this.info.questions.length - 1;
  }

  @action
  async getTestInfo(testId: number) {
    let response = await getTest(testId);
    console.log(response);
    runInAction(() => {
      this.info = response;
    });
  }

  @action
  async getFinishedTests() {
    let response = await finishedTests();
    runInAction(() => {
      return (this.finishedTests = response);
    });
  }

  @action
  async startTest(id: number) {
    // Create test attempt and save it locally
    let attempt = await startAttempt(id);
    if (attempt) {
      runInAction(() => {
        this.attempt = attempt;
      });
    }
  }

  // Progress from 1 to 100
  // TODO: Count by answers and non flagged questions
  @computed get progress() {
    if (!this.info || !this.info.questions) return 0;
    return (
      (100 / this.info.questions.length) * (this.currentQuestionNumber + 1)
    );
  }

  @action
  async nextQuestion() {
    if (this.info && !this.loading) {
      if (this.currentQuestionNumber < this.info.questions.length - 1) {
        runInAction(() => {
          this.loading = true;
        });
        await this.submitCurrentAnswer();
        runInAction(() => {
          this.currentQuestionNumber += 1;
          this.loading = false;
        });
      }
    }
    // Submit the previous question
  }

  @action
  async previousQuestion() {
    if (this.info && !this.loading) {
      if (this.currentQuestionNumber > 0) {
        runInAction(() => {
          this.loading = true;
        });
        await this.submitCurrentAnswer();
        runInAction(() => {
          this.currentQuestionNumber -= 1;
          this.loading = false;
        });
      }
    }
  }

  /**
   * Used to submit the answer to the server (Only for non file questions)
   * @param answer
   */
  @action
  async submitCurrentAnswer() {
    // If no changes detected we don't need to submit
    if (!this.answerHasChanges) return;

    let answer = this.currentAnswer;
    switch (answer.question_type) {
      case QuestionType.Text:
      case QuestionType.Select: {
        let result = await submitAnswer(this.attempt.id, answer);
        console.log(result);
        break;
      }
      case QuestionType.Upload:
        console.warn(
          "This might be a hidden error, upload question can't be here"
        );
        break;
      default:
        console.warn("Unknown type of question");
        break;
    }

    runInAction(() => {
      this.answerHasChanges = false;
    });
  }

  @action
  fileExists(file: File): Boolean {
    let currentAnswer = this.currentAnswer as AnswerUploadFile;
    if (!currentAnswer) return false;
    if (!currentAnswer.file) return false;
    if (currentAnswer.filename != file.name) return false;
    return true;
  }

  @action
  async handleNewFiles(newFiles: Array<File>) {
    let tempFiles: Array<File> = [];

    // Filter out non existent files
    tempFiles = newFiles.filter((item) => !this.fileExists(item));

    // Filter out too big files
    tempFiles = tempFiles.filter((item) => {
      if (item.size <= DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        return true;
      } else {
        toast(`${item.name} is too big`);
        return false;
      }
    });

    // Skip if no files are present
    if (tempFiles.length == 0) {
      return;
    }

    let file = tempFiles[0];

    let newUpload: AnswerUploadFile = {
      flagged: false,
      id: this.currentQuestion.id,
      file: file,
      question_type: QuestionType.Upload,
      filename: file.name,
      filesize: file.size,
      state: FileState.Uploading,
      uploadProgress: 0,
      fileUrl: undefined,
    };

    await this.setAnswer(newUpload);
    await this.uploadFiles();
  }

  @action
  async uploadFiles() {
    console.log("uploadfiles", toJS(this.currentAnswer));

    let fileToUpload = this.currentAnswer as AnswerUploadFile;

    try {
      await submitAnswerFile(this.attempt.id, fileToUpload, (progress) => {
        console.log("Progress", progress);
        runInAction(() => {
          this.setAnswer(
            {
              ...(this.currentAnswer as AnswerUploadFile),
              uploadProgress: progress,
            },
            false
          );
        });
      });

      runInAction(() => {
        toast.success("File is uploaded successfully");
        this.setAnswer(
          {
            ...(this.currentAnswer as AnswerUploadFile),
            uploadProgress: 100,
            state: FileState.Uploaded,
          },
          false
        );
      });
    } catch (err) {
      runInAction(() => {
        this.setAnswer(
          {
            ...(this.currentAnswer as AnswerUploadFile),
            uploadProgress: 0,
            state: FileState.Failed,
          },
          false
        );
      });
    }
  }
  @action
  async removeFile() {
    testStore.setAnswer({
      ...testStore.currentAnswer,
      // @ts-ignore
      file: undefined,
      filename: undefined,
      filesize: undefined,
      uploadProgress: undefined,
      fileUrl: undefined,
    });
    await this.uploadFiles();
  }

  @action
  async finishTest() {
    // Submit last answer before quitting the test
    await this.submitCurrentAnswer();

    // TODO: ENABLE THIS FOR WORKING TESTS
    await endAttempt(this.attempt.id);

    this.state = TestState.Complete;

    if (userStore.info.form_after_test && userStore.info.can_take_survey) {
      modalStore.dialogModal({
        description: t("ModalPassTheSurvey.description"),
        title: t("ModalPassTheSurvey.Pass a sociological survey?"),
        onYes: () => {
          socialStore.show();
        },
        onNo: () => {},
        keepOpenOnBackgroundClick: true,
        okText: t("form.button.OK"),
        cancelText: t("ModalFinishTesting.button.cancel"),
      });
    }
    this.resetState();
  }

  /**
   * Saves answers to local storage
   * @param answer answer to be made
   * @param noFlag Do not flag the answer as changed to prevent double uploading
   */
  @action
  async setAnswer(answer: Answer, flag: boolean = true) {
    if (answer.question_type == QuestionType.Text) {
      let existingAnswer = this.getAnswer<AnswerText>(answer.id);
      if (existingAnswer) {
        if (answer.text != existingAnswer.text) {
          runInAction(() => {
            this.answers = this.answers.map((item) =>
              item.id == existingAnswer.id ? answer : item
            );
            if (flag) this.answerHasChanges = true;
          });
        }
      } else {
        runInAction(() => {
          if (flag) this.answerHasChanges = true;
          this.answers = [...this.answers, answer];
        });
      }
    }
    // Select
    if (answer.question_type == QuestionType.Select) {
      runInAction(() => {
        if (flag) this.answerHasChanges = true;
        let existingAnswer = this.getAnswer<AnswerSelect>(answer.id);
        if (existingAnswer) {
          this.answers = this.answers.map((item) =>
            item.id == existingAnswer.id ? answer : item
          );
        } else {
          this.answers = [...this.answers, answer];
        }
      });
    }

    // Upload immediately ig?
    if (answer.question_type == QuestionType.Upload) {
      let existingAnswer = this.getAnswer<AnswerUploadFile>(answer.id);
      if (existingAnswer) {
        this.answers = this.answers.map((item) =>
          item.id == existingAnswer.id ? answer : item
        );
      } else {
        this.answers = [...this.answers, answer];
      }
    }
  }

  @action
  getAnswer<T extends AnswerBase>(id: number): T | null {
    let found = this.answers.find((answer) => answer.id == id);
    if (!found) {
      return null;
    }

    return found as unknown as T;
  }

  // Required to make observable
  constructor() {
    makeObservable(this);
  }
}

const hydrate = create({
  storage: localStorage, // or AsyncStorage in react-native.
  // default: localStorage
  jsonify: true, // if you use AsyncStorage, here shoud be true
  // default: true
});

const testStore = new TestSession();

hydrate("testStore", testStore).then(() => {});

export default testStore;

export const testStoreType = typeof TestSession;
