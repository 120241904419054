import { makeObservable, observable, action, runInAction } from "mobx"


class SocialStore {
    @observable shown: boolean = false;

    
    @action
    hide(){
        this.shown = false
    }
    @action
    show(){
        this.shown = true
    }

    // Required to make observable
    constructor() {
        makeObservable(this)
    }
}



const socialStore = new SocialStore();



export default socialStore;

export const socialStoreType = typeof SocialStore 