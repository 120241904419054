import { Helmet } from "react-helmet";
import { Layout } from "../components/layouts/Layout";
import { Section } from "../components/Section/Section";
import i18n from "../i18n";
const t = i18n.t;

export function NoMatch() {
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>404 страница не найдена</title>
            {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <h1>Not found</h1>
    </Layout>
}