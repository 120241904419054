
import styles from "./PlayVideo.module.css";



export const PlayVideo = () => {
    return (
        <div className={styles.containerBg}>
            <div className={styles.bg}>
                <div className={styles.triangle}></div>
            </div>
        </div>
    )
}