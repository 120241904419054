import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import userStore from "../../store/userStore";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const HeroChangeLaunguage = () => {
  const [tooltipView, setTooltipView] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  let { newsId, testId } = useParams();

  useEffect(() => {
    (async () => {
      userStore.downloadLanguages();
    })();
  }, []);

  return (
    <div
      className="hero-functional__change"
      onMouseEnter={() => {
        setTooltipView(true);
      }}
    >
      <div className="hero-functional__change-language">
        <span>{userStore.locale || ""}</span>
        <i className="arrow top"></i>
      </div>

      {tooltipView && (
        <div
          className="hero-tooltip-language__container"
          onMouseLeave={() => {
            setTooltipView(false);
          }}
        >
          {userStore.languages &&
            userStore.languages.map((item) => (
              <div
                key={item.locale}
                className="hero-tooltip-language__lang"
                onClick={async () => {
                  let languageChanged = userStore.locale != item.locale
                  await userStore.setLanguage(item);
                  
                  if (languageChanged) {
                    userStore.saveLanguage();
                  }
                  
                  setTooltipView(false);
                  //   alert(location.pathname);
                  location.pathname === `/news/${newsId}/` && navigate("/news");
                  location.pathname === `/news/${newsId}` && navigate("/news");
                  location.pathname === `/test/${testId}/` &&
                    navigate("/tests");
                  location.pathname === `/test/${testId}` && navigate("/tests");
                }}
              >
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default observer(HeroChangeLaunguage);
