import axios from "axios";
import { makeObservable, observable, action, runInAction, toJS, isObservableObject } from "mobx"
import { create, persist } from "mobx-persist";
import { getLanguageList, Language } from "../api/languages";
import { authorize, getCurrentUser, updateCurrentUser } from "../api/user";
import i18n from './../i18n';
import bannerStore from "./bannerStore";
import newsStore from "./newsStore";
import testStore from "./testStore";
interface UserInfo {
    id: number;
    email: string;
    age: number;
    name: string;
    sex: number;
    language: Language;
    home: string;
    locale?: string;
    birthday: string;
    mailing: boolean;
    /**
     * Client side flag  meaning that the user wants to take part in the social survey
     */
    form_after_test: boolean;
    part_in_contest: boolean;
    /**
     * This flag is server side check for an ability to pass the social survey
     */
    can_take_survey?: boolean;
}

class User {
    @observable languages?: Array<Language> = undefined
    @observable languagesLoading?: boolean = false
    @observable startup?: boolean = true
    @persist("object") @observable language?: Language = undefined
    @persist @observable locale?: string = undefined
    @persist @observable finished = false
    @persist @observable token?: string = undefined
    @persist("object") @observable info?: UserInfo = undefined

    @action
    async login(username: string, password: string) {
        let token = await authorize(username, password)
        runInAction(() => {
            this.token = token;
        })
        if (this.token) {
            let info = await this.getUserInfo();
        }
        return !!this.token
    }

    @action
    async logout() {
        runInAction(() => {
            this.token = undefined;
            this.info = undefined;

            testStore.resetState();
        });
    }

    @action
    async triggerContentReload() {
        bannerStore.getBanner(true);
        newsStore.getNews(true);
    }

    @action
    async setLanguage(language: Language, initial = false) {
        runInAction(() => {
            // Check if the language actually changed
            let languageChanged = language.locale !== this.locale;

            this.language = language;
            this.locale = language?.locale;
            i18n.changeLanguage(language.locale);


            if (initial || languageChanged) {
                // Reload assets in the new language
                this.triggerContentReload();
            }
        });
    }

    @action
    async saveLanguage() {
        if (this.token && this.language) {
            await updateCurrentUser({ locale: this.language.locale });
        }
    }

    /**
     * Gets language list from server
     */
    @action
    async downloadLanguages(forceReload = false) {
        if (!forceReload && this.languagesLoading) { return; }
        if (!forceReload && this.languages) { return; }

        runInAction(() => {
            this.languagesLoading = true
        });

        try {
            let list = await getLanguageList();
            runInAction(() => {
                this.languages = list;
                // Set language variable if locale is compatible
                if (i18n.language) {
                    let lang = list.find((i) => i.locale == i18n.language);
                    if (lang) {
                        this.language = lang;
                    }
                }

                this.languagesLoading = false
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.warn("Got axios error when loading the languages", e)
            } else {
                console.warn("Got unknown when loading the languages", e)
            }
            runInAction(() => {
                {
                    this.languagesLoading = false
                }
            })

        }
    }


    @action
    async getUserInfo() {
        try {
            let info = await getCurrentUser()
            runInAction(() => {
                // @ts-ignore
                this.info = info;
                if (info.language && info.language.locale) {
                    this.setLanguage(info.language, true)
                }
            });
            return info;
        } catch (e) {
            if (axios.isAxiosError(e)) {

            } else {

            }
            // Reload assets in the new language
            this.triggerContentReload();
        }
    }

    // Required to make observable
    constructor() {
        makeObservable(this)
    }
}

const hydrate = create({
    storage: localStorage,   // or AsyncStorage in react-native.
    // default: localStorage
    jsonify: true  // if you use AsyncStorage, here shoud be true
    // default: true
})

const userStore = new User();

hydrate("user", userStore).then(async () => {
    if (userStore.language) {
        userStore.setLanguage(userStore.language);
    }
    await userStore.getUserInfo();
})

export default userStore;

export const userStoreType = typeof User 