import { format } from "date-fns";

export const parseDate = (date: string): Date => {
  let parse = new Date(date);
  return parse;
};

export const formatDate = (date: Date): String => {
  return format(date, "yyyy-MM-dd");
};

export const initiativesName = (fullName: string) => {
  if (!fullName) {
    return;
  }
  let names = fullName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const declination = (value, words) => {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
};

/**
 * Sleep n ms
 * @param milliseconds Sleep time in ms
 * @returns 
 */
export const sleep = (milliseconds): Promise<void> => {
  return new Promise(resolve => {
      return setTimeout(resolve, milliseconds)
  });
};