import axiosLib, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import userStore from "../store/userStore";
import i18n from "../i18n";
const t = i18n.t;

// Create client and init the token if stored
let client = axiosLib.create({
  baseURL: "/api",
  timeout: 5000,
  // disable cookies..
  withCredentials: false,
});

// Add a request interceptor
client.interceptors.request.use(
  function (config: AxiosRequestConfig) {
    // Get token from the store
    if (userStore.token) {
      config.headers = {
        Authorization: `Token ${userStore.token}`,
      };
    }

    if (i18n.language) {
      config.headers["locale"] = i18n.language;
    }

    // Do something before request is sent
    return config;
  },
  function (error: AxiosError) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  function (response: AxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error: AxiosError) {
    // Log out if unauthorized
    if (error.response && error.response.status == 401) {
      if (userStore.token) {
        toast.error(t("Unauthorized"));
      }
      userStore.logout();
    }

    if (error.response && error.response.status == 500) {
      // toast.error(t(`Server error on route {{route}}`, {route: error.response.config.url}))
      console.error(`Server error on route {{route}}`, {
        route: error.response.config.url,
      });
    }

    return Promise.reject(error);
  }
);

export default client;
