import { createTheme } from '@mui/material/styles';


let theme = createTheme({
    palette: {
        primary: {
            main: '#4F9681',
        },
        secondary: {
            main: '#196050',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
    },
});

export default theme;