import { useTranslation } from "react-i18next";
import testStore, { AnswerText } from "../../store/testStore";
import { alpha, styled } from "@mui/material/styles";
import BootstrapInput from "../form/Input";
import { observer } from "mobx-react-lite";
import { QuestionText } from "../../api/tests";

interface QuestionTextInputProps {
  question: QuestionText;
  answer?: AnswerText;
}

function QuestionTextInput({ question, answer }: QuestionTextInputProps) {
  let { t } = useTranslation();

  function updateAnswer(val: string) {
    let answer: AnswerText = {
      id: question.id,
      question_type: question.question_type,
      flagged: false,
      text: val,
    };

    testStore.setAnswer(answer);
    console.log(testStore.answers);
  }

  return (
    <div>
      <AnswerDesriptionText>
        {t("TestPage.label.YourReply")}
      </AnswerDesriptionText>
      <BootstrapInput
        multiline
        fullWidth
        minRows={4}
        placeholder={t("TestPage.placeholder.YourReply") + "..."}
        value={answer ? answer.text : ""}
        onChange={(val: any) => {
          updateAnswer(val.currentTarget.value);
        }}
      />
    </div>
  );
}
// Wrapper for question answer content
const AnswerDesriptionText = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: 10,
    fontWeight: 200,
    color: "border: 0.4px solid rgba(53, 102, 73, 0.9)",
    letterSpacing: "0.05em",
    lineHeight: "28px",
    WebkitTextStrokeWidth: "0.4px",
    WebkitTextStrokeColor: "#196050",
    padding: 0,
    margin: 0,
    marginTop: 10,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: 14,
    marginTop: 35,
    marginBottom: 23,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: 14,
    marginTop: 10,
    marginBottom: 23,
  },
}));

export default observer(QuestionTextInput);
