import axios from "axios";
import client from "./axios";
import i18n from "../i18n";
const t = i18n.t;

export interface Language {
    id: number;
    locale: string;
    name: string;
    updated_at: string;
    created_at :string;
}
export async function getLanguageList(): Promise<Array<Language>> {
    let response = await client.get("/langs/");
    if (response.status != 200) {
        throw new Error(t("Failed to get the languages"));
    }
    return response.data;
}