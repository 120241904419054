import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Layout } from "../components/layouts/Layout";

import * as yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { ControlledBootstrapInput } from "../components/form/Input";
import { confirmUser } from "../api/user";
import { useTranslation } from "react-i18next";

const schema = yup
  .object({
    password: yup.string().min(8).max(100),
    password2: yup.string().min(8).max(100),
  })
  .required();

export default function ConfirmRegistration() {
  let { t } = useTranslation();
  let { uid, key } = useParams();
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      let confirmed = false;
      try {
        confirmed = await confirmUser(uid, key);
      } catch (e) {}
      if (confirmed) {
        toast.success(t("Toast.ConfirmRegistration.Profile Verified"));
        navigate("/");
      } else {
        toast.error(t("Toast.ConfirmRegistration.Link is not valid"));
        navigate("/");
      }
    })();
  });

  return <Layout></Layout>;
}
