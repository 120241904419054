import client from "./axios";

export async function submitSocialSurvey(data: object) {
    let response = await client.post(`/submit_social_survey/`, data);

    if (response.status === 200) {
        return await response.data;
    } else {
        throw new Error("Error submitting social survey")
    }


}