import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    .use(Backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        fallbackLng: "ru",// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        supportedLngs: ["ru", "en", "kbd", "ady", "ar", "tr"],
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;