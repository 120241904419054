import { forwardRef } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Arrow.module.scss";

type ArrowProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  direction: "left" | "right";
  link?: string;
  arrowTesting?: boolean;
  disable?: boolean;
};

export const Arrow = forwardRef<HTMLDivElement, ArrowProps>(function Arrow(
  props: ArrowProps,
  ref
) {
  let content = (
    <div
      {...props}
      className={`${styles.arrow} ${props.arrowTesting && styles.arrowLarge} ${
        props.disable && styles.endArrowStyle
      }`}
      ref={ref}
    >
      <div className={styles.bg}>
        <div className={`long-arrow-${props.direction}`}></div>
      </div>
    </div>
  );

  // If link, then wrap in a link
  if (props.link) {
    content = <NavLink to={props.link}>{content}</NavLink>;
  }

  return content;
});
