import styles from "./Footer.module.scss";
import { Section } from "../Section/Section";
import AguOneImage from "../../assets/image/agu1.png";
import AguTwoImage from "../../assets/image/agu2.png";
import AguThreeImage from "../../assets/image/agu3.png";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  let { t } = useTranslation();
  const [year, setYear] = useState<number>();
  useEffect(() => {
    const date = new Date().getFullYear();
    return setYear(date);
  }, []);
  return (
    <Section>
      <div className={styles.container}>

        <div
          className={styles.title}
          style={{
            fontFamily: "Casual Contact MF",
          }}
        > <Link to={"/"}>
            <span>{t("InfoSite.Circassian")}</span>
            <span>{t("InfoSite.Deer")}</span>
          </Link>
        </div>

        <div className={styles.privacy}>
          <span className={styles.privacyDesktop}>
            {year} · {t("Footer.AllRightsReserved")}
          </span>
          <span className={styles.privacyMobile}>
            {year}
            <br></br>
            {t("Footer.AllRightsReserved")}
          </span>
        </div>

        <div className={styles.image}>
          <img src={AguOneImage} alt="" />
          <img src={AguThreeImage} alt="" />
          <img src={AguTwoImage} alt="" />
        </div>

        <div className={styles.contact}>
          {/* <span>
            <a href={"tel:+7(000)000-99-00"}>+7(000)000-99-00</a>
          </span> */}
          <span className={styles.contactUnderline}>
            <a href={"mail:mail@adygland.ru"}>mail@adygland.ru</a>
          </span>
        </div>
      </div>
    </Section>
  );
};
