import axios from "axios";
import client from "./axios";

/**
 * Get the token for the auth
 * @param username 
 * @param password 
 * @param token if we decide to use recapcha token from it
 * @returns 
 */
export async function authorize(username: string, password: string, token?: string): Promise<string> {
    let response = await client.post("/auth-token/", {
        username, password, token
    });

    if (response.status != 200) {
        throw new Error("Failed to authorize");
    }

    return response.data.token;
}

/**
 * Get the current user
 * @param username 
 * @param password 
 * @param token if we decide to use recapcha token from it
 * @returns 
 */
export async function getCurrentUser(): Promise<APIUserData> {
    let response = await client.get("/me/");

    if (response.status != 200) {
        throw new Error("Failed to authorize");
    }

    return response.data;
}


/**
 * Update current user
 * @param username 
 * @param password 
 * @param token if we decide to use recapcha token from it
 * @returns 
 */
 export async function updateCurrentUser(userInfo: Partial<APIUserData>): Promise<APIUserData> {
    let response = await client.post("/me/", userInfo);

    // if (response.status != 200) {
    //     throw new Error("Failed to authorize");
    // }

    return response.data;
}

/**
 * Get the current user
 * @param username 
 * @param password 
 * @param token if we decide to use recapcha token from it
 * @returns 
 */
interface RegisterUserData {
    id?: number,
    name: string,
    home: string,
    age: string,
    birthday: string,
    email: string,
    sex: string,
    mailing: string,
    form_after_test: string,
    part_in_contest: string
}

interface APIUserData {
    id: number,
    name: string,
    home: string,
    age: string,
    language: {
        id: number;
        locale: "en"|"ru",
        name: string;
        updated_at: string;
        created_at :string
    },
    birthday: string,
    email: string,
    sex: number,
    mailing: boolean,
    form_after_test: boolean,
    part_in_contest: boolean
    // Only used for changing language
    locale?: string;
}

export async function registerUser(userData: RegisterUserData): Promise<APIUserData> {
    let response = await client.post("/register/", userData);

    if (response.status != 201) {
        throw new Error("Failed to authorize");
    }

    return response.data;
}

export async function confirmUser(uid: string, token: string) {
    let response = await client.get(`/activate/${uid}/${token}/`);
    if (response.status === 200) {
        return true;

    } else {
        return false;
    }
}


export async function restorePassword(uid: string, token: string, password: string): Promise<boolean> {
    let response = await client.post(`/restore/${uid}/${token}/`, {
        password: password
    });

    if (response.status === 200) {
        return true
    }else {
        throw new Error("Something went wrong with password restore");
    }
}

export async function makeRestoreLink(email: string): Promise<boolean> {
    let response = await client.post(`/restore/`, {
        email: email
    });

    if (response.status === 200) {
        return true
    }else {
        throw new Error("Something went wrong with password restore");
    }
}