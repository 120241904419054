import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "./Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import GradientButton from "../Button/GradientButton";
import { FormControl } from "@mui/material";
import { ModalTextProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


export function AlertModal(props: ModalTextProps) {
  let { t } = useTranslation();
  
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
    props.setOpen(false);
  }
  let navigate = useNavigate();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className="modalSuccessTesting modal">
            <h2 className="modal-title" id="transition-modal-title">
              {/* Тестирование завершено успешно! */}
              {props.title}
            </h2>
            <Typography
              className="modal-description"
              id="transition-modal-description"
            >
              {/* Результаты будут направлены Вам на элетронную почту */}
              {props.description}
            </Typography>

            <FormControl variant="standard" margin="none" fullWidth>
              <div className="modalSuccessBtn">
                <GradientButton
                  onClick={() => {
                    handleClose();
                  }}
                  fullWidth
                >
                  {t("form.button.OK")}
                </GradientButton>
              </div>
            </FormControl>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
