import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "./Modal";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import FlatButton from "../Button/FlatButton";
import { FormControlLabel, FormGroup, styled } from "@mui/material";
import { ModalProps, ModalTextProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinearProgress from "../Progress";
import { Arrow } from "../Arrow/Arrow";
import { getQuestions, QuestionType } from "../../assets/socialPoll/questions";
import { useEffect, useState } from "react";
import BootstrapInput from "../form/Input";
import QuestionSelectInput, {
  CheckBox,
  CheckMarkIcon,
  QuestionSelectInputWrapper,
} from "../Question/QuestionSelectInput";
import { Slider } from "../form/DiscreteSlider";
import classNames from "classnames";
import i18n from "../../i18n";
import userStore from "../../store/userStore";
import { submitSocialSurvey } from "../../api/survey";
import { observer } from "mobx-react-lite";
import socialStore from "../../store/socialStore";
import modalStore from "../../store/modalStore";
import CustomTooltip from "../Tooltip";

interface Answer {
  id: number;
  value: any;
  options?: number[];
}

export interface IQuestion {
  type: QuestionType;
  fieldName: string;
  text: string;
  options?: string[];
  placeholder?: string;
}

function ModalSocial(props: ModalProps) {
  let navigate = useNavigate();
  const open = socialStore.shown;
  const [answers, setAnswers] = useState<Array<Answer>>([]);
  const [questions, setQuestions] = useState<IQuestion[]>(() =>
    getQuestions(userStore.locale)
  );

  useEffect(() => {
    setQuestions(() => getQuestions(userStore.locale));
  }, [open]);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const question =
    questions.length > currentQuestion ? questions[currentQuestion] : null;
  const nextQuestion = () => {
    if (currentQuestion >= questions.length - 1) {
      return;
    }
    if (!currentAnswer || currentAnswer.value === "") {
      return;
    }
    console.log(currentAnswer);
    setCurrentQuestion(currentQuestion + 1);
  };
  const prevQuestion = () => {
    if (currentQuestion === 0) {
      return;
    }
    setCurrentQuestion(currentQuestion - 1);
  };
  const [isLastQuestion, setIslastQuestion] = useState(false);
  useEffect(() => {
    if (currentQuestion === questions.length - 1) {
      setIslastQuestion(true);
    } else {
      setIslastQuestion(false);
    }
  }, [currentQuestion]);

  let { t } = useTranslation();
  const handleClose = () => {
    socialStore.hide();
  };

  const currentAnswer = answers.find((a) => a.id == currentQuestion);

  const onChange = (value: any) => {
    console.log("On change", value);

    if (question.type == QuestionType.Number) {
      value = (value as unknown as string).replaceAll(/\D/g, "");
    }

    if (
      question.type == QuestionType.Text ||
      question.type == QuestionType.Number
    ) {
      if (!currentAnswer) {
        setAnswers((answers) => [
          ...answers,
          {
            id: currentQuestion,
            value: value,
          },
        ]);
      } else {
        setAnswers((answers) => {
          let filtered = answers.filter((a) => a.id != currentQuestion);
          return [...filtered, { ...currentAnswer, value }];
        });
      }
    }
    if (question.type == QuestionType.Range) {
      if (!currentAnswer) {
        setAnswers((answers) => [
          ...answers,
          {
            id: currentQuestion,
            value: value,
          },
        ]);
      } else {
        setAnswers((answers) => {
          let filtered = answers.filter((a) => a.id != currentQuestion);
          return [...filtered, { ...currentAnswer, value }];
        });
      }
    }
    if (question.type == QuestionType.Select) {
      // Don't judge me
      value = value as {
        index: number;
        enabled: boolean;
      };

      if (!currentAnswer) {
        setAnswers((answers) => [
          ...answers,
          {
            id: currentQuestion,
            value: question.options[value.index],
            options: [value.index],
          },
        ]);
      } else {
        setAnswers((answers) => {
          let filtered = answers.filter((a) => a.id != currentQuestion);

          let filteredOptions = currentAnswer.options.filter(
            (o) => o != value.index
          );

          if (value.enabled) {
            filteredOptions.push(value.index);
          }

          let strings = [...filteredOptions]
            .sort()
            .map((item) => question.options[item]);

          return [
            ...filtered,
            {
              ...currentAnswer,
              options: filteredOptions,
              value: strings.join(", "),
            },
          ];
        });
      }
    }
    if (question.type == QuestionType.Boolean) {
      // Don't judge me
      value = value as {
        index: number;
        enabled: boolean;
      };

      if (!currentAnswer) {
        let newValue = undefined;
        if (value.index === 0) {
          newValue = true;
        } else if (value.index === 1) {
          newValue = false;
        }
        setAnswers((answers) => [
          ...answers,
          {
            id: currentQuestion,
            value: newValue,
            options: [value.index],
          },
        ]);
      } else {
        setAnswers((answers) => {
          let filtered = answers.filter((a) => a.id != currentQuestion);

          let newValue = undefined;
          if (value.index === 0) {
            newValue = true;
          } else if (value.index === 1) {
            newValue = false;
          }

          return [
            ...filtered,
            { ...currentAnswer, options: [value.index], value: newValue },
          ];
        });
      }
    }
  };

  const onSubmit = async () => {
    if (answers.length == 0) {
      return;
    }

    let data = {};

    answers.forEach((answer) => {
      let question = questions[answer.id];

      data[question.fieldName] = answer.value;
    });

    console.log(data);

    await submitSocialSurvey(data);
    handleClose();
    modalStore.alertModal({
      title: t("ModalSocial.Thank you for your activity"),
      description: t("ModalSocial.You help us get better"),
      onClose: () => {
        navigate("/");
      },
    });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className="ModalSocial modal">
            <ContainerSocial>
              <QuestionSocial>
                <div className="questionNumber">
                  <span>
                    {t("TestPage.infoQuestion.question")} {currentQuestion + 1}{" "}
                    {t("TestPage.infoQuestion.from")} {questions.length}
                  </span>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={
                    question
                      ? (100 / questions.length) * (currentQuestion + 1)
                      : 0
                  }
                />
              </QuestionSocial>
              <Content>
                <div className="ModalSocialContentTitle">
                  <span>{question?.text || ""}</span>
                </div>
                <div className="ModalSocialContentEnter">
                  {question.type === QuestionType.Text && (
                    <BootstrapInput
                      sx={{ p: "2px" }}
                      fullWidth
                      value={(currentAnswer && currentAnswer.value) || ""}
                      placeholder={question.placeholder}
                      onChange={(e) => {
                        onChange(e.currentTarget.value);
                      }}
                    />
                  )}
                  {question.type === QuestionType.Select && (
                    <>
                      <QuestionSelectInputWrapper>
                        <FormGroup
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            overflow: "hidden",
                          }}
                        >
                          {[t("ModalSocial.Yes"), t("ModalSocial.No")].map(
                            (item, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  sx={{
                                    marginTop: {
                                      xs: "10px",
                                      md: "14px",
                                      lg: "20px",
                                    },
                                    display: "flex",
                                    justifyContent: {
                                      xs: "flex-start",
                                    },
                                    alignItems: {
                                      xs: "flex-start",
                                      md: "center",
                                    },

                                    flexBasis: "45%",
                                    "& .MuiFormControlLabel-label": {
                                      xs: {
                                        fontFamily: "a_FuturicaBs",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        color: "rgba(53, 102, 73, 0.9)",
                                        WebkitTextStrokeWidth: "0.1px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                      md: {
                                        fontWeight: "200",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        WebkitTextStrokeWidth: "0.1px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                    },
                                    "&:hover .MuiFormControlLabel-label": {
                                      xs: {
                                        fontFamily: "a_FuturicaBs",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        WebkitTextStrokeWidth: "0.3px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                        color: "rgba(53, 102, 73, 0.9)",
                                      },
                                      md: {
                                        fontSize: "16px",
                                        fontWeight: "200",
                                        lineHeight: "20px",
                                        WebkitTextStrokeWidth: "0.3px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                    },
                                    "&.active": {
                                      "& .MuiFormControlLabel-label": {
                                        xs: {
                                          fontFamily: "a_FuturicaBs",
                                          fontStyle: "normal",
                                          fontWeight: "200",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                          WebkitTextStrokeWidth: "0.3px",
                                          WebkitTextStrokeColor:
                                            "rgba(53, 102, 73, 0.9)",
                                          color: "rgba(53, 102, 73, 0.9)",
                                        },
                                        md: {
                                          fontSize: "16px",
                                          lineHeight: "20px",
                                          fontWeight: "200",
                                          WebkitTextStrokeWidth: "0.3px",
                                          WebkitTextStrokeColor:
                                            "rgba(53, 102, 73, 0.9)",
                                        },
                                      },
                                    },
                                  }}
                                  className={classNames({
                                    active:
                                      !!currentAnswer?.options?.includes(index),
                                  })}
                                  onClick={(e) => {
                                    console.log(index);
                                    console.log(
                                      currentAnswer?.options?.includes(index)
                                    );
                                    onChange({
                                      index: index,
                                      enabled:
                                        !currentAnswer?.options?.includes(
                                          index
                                        ),
                                    });
                                  }}
                                  control={
                                    <CheckBox
                                      sx={{}}
                                      className={classNames({
                                        active:
                                          !!currentAnswer?.options?.includes(
                                            index
                                          ),
                                      })}
                                    >
                                      <CheckMarkIcon />
                                      {/* <CheckBox type="checkbox" checked={options.includes(item.id)} onChange={(e) => handleChange(item.id, e.target.checked)} /> */}
                                    </CheckBox>
                                  }
                                  label={item}
                                />
                              );
                            }
                          )}
                        </FormGroup>
                      </QuestionSelectInputWrapper>
                    </>
                  )}
                  {question.type === QuestionType.Boolean && (
                    <>
                      <QuestionSelectInputWrapper>
                        <FormGroup
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            flexDirection: "row",
                            overflow: "hidden",
                          }}
                        >
                          {question.options &&
                            question.options.map((item, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  sx={{
                                    marginTop: {
                                      xs: "10px",
                                      md: "14px",
                                      lg: "20px",
                                    },
                                    flexBasis: "45%",
                                    "& .MuiFormControlLabel-label": {
                                      xs: {
                                        fontFamily: "a_FuturicaBs",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        color: "rgba(53, 102, 73, 0.9)",
                                        WebkitTextStrokeWidth: "0.1px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                      md: {
                                        fontWeight: "200",
                                        fontSize: "16px",
                                        lineHeight: "20px",
                                        WebkitTextStrokeWidth: "0.1px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                    },
                                    "&:hover .MuiFormControlLabel-label": {
                                      xs: {
                                        fontFamily: "a_FuturicaBs",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "18px",
                                        WebkitTextStrokeWidth: "0.3px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                        color: "rgba(53, 102, 73, 0.9)",
                                      },
                                      md: {
                                        fontSize: "16px",
                                        fontWeight: "200",
                                        lineHeight: "20px",
                                        WebkitTextStrokeWidth: "0.3px",
                                        WebkitTextStrokeColor:
                                          "rgba(53, 102, 73, 0.9)",
                                      },
                                    },
                                    "&.active": {
                                      "& .MuiFormControlLabel-label": {
                                        xs: {
                                          fontFamily: "a_FuturicaBs",
                                          fontStyle: "normal",
                                          fontWeight: "200",
                                          fontSize: "12px",
                                          lineHeight: "18px",
                                          WebkitTextStrokeWidth: "0.3px",
                                          WebkitTextStrokeColor:
                                            "rgba(53, 102, 73, 0.9)",
                                          color: "rgba(53, 102, 73, 0.9)",
                                        },
                                        md: {
                                          fontSize: "16px",
                                          lineHeight: "20px",
                                          fontWeight: "200",
                                          WebkitTextStrokeWidth: "0.3px",
                                          WebkitTextStrokeColor:
                                            "rgba(53, 102, 73, 0.9)",
                                        },
                                      },
                                    },
                                  }}
                                  className={classNames({
                                    active:
                                      !!currentAnswer?.options?.includes(index),
                                  })}
                                  onClick={(e) => {
                                    console.log(index);
                                    console.log(
                                      currentAnswer?.options?.includes(index)
                                    );
                                    onChange({
                                      index: index,
                                      enabled:
                                        !currentAnswer?.options?.includes(
                                          index
                                        ),
                                    });
                                  }}
                                  control={
                                    <CheckBox
                                      className={classNames({
                                        active:
                                          !!currentAnswer?.options?.includes(
                                            index
                                          ),
                                      })}
                                    >
                                      <CheckMarkIcon />
                                      {/* <CheckBox type="checkbox" checked={options.includes(item.id)} onChange={(e) => handleChange(item.id, e.target.checked)} /> */}
                                    </CheckBox>
                                  }
                                  label={item}
                                />
                              );
                            })}
                        </FormGroup>
                      </QuestionSelectInputWrapper>
                    </>
                  )}
                  {question.type === QuestionType.Number && (
                    <BootstrapInput
                      sx={{ p: "2px" }}
                      fullWidth
                      value={(currentAnswer && currentAnswer.value) || ""}
                      placeholder={question.placeholder}
                      onChange={(e) => {
                        onChange(e.currentTarget.value);
                      }}
                    />
                  )}
                  {question.type === QuestionType.Range && (
                    <div className="ModalSocialSlider">
                      <Slider
                        aria-label={question.text}
                        valueLabelDisplay="auto"
                        step={1}
                        value={currentAnswer?.value || 5}
                        marks={[...Array(11).keys()]
                          .map((x) => x++)
                          .map((i) => ({
                            value: i,
                            label: i.toString(),
                          }))}
                        min={1}
                        max={10}
                        onChange={(e) => {
                          // @ts-ignore
                          onChange(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Content>
              <FooterButton>
                <div className="ModalSocialButtonArrow">
                  <CustomTooltip title={t("TestPage.tooltip.PreviousQuestion")} placement="top">
                    <Arrow
                      direction="left"
                      arrowTesting={true}
                      onClick={prevQuestion}
                    />
                  </CustomTooltip>
                  <CustomTooltip title={t("TestPage.tooltip.NextQuestion")} placement="top">
                    <Arrow
                      direction="right"
                      arrowTesting={true}
                      onClick={nextQuestion}
                      disable={
                        !currentAnswer ||
                        currentAnswer.value === "" ||
                        isLastQuestion
                      }
                    />
                  </CustomTooltip>
                </div>
                <div className="ModalSocialButton">
                  {isLastQuestion && (
                    <GradientButton onClick={onSubmit}>
                      {t("TestPage.button.FinishTest")}
                    </GradientButton>
                  )}
                </div>
              </FooterButton>
            </ContainerSocial>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default observer(ModalSocial);

const ContainerSocial = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowX: "hidden",
  },
  [theme.breakpoints.up("md")]: {
    overflow: "hidden",
  },
}));
const QuestionSocial = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    "& .questionNumber": {
      span: {
        fontSize: 14,
        fontWeight: 200,
        color: "rgba(53, 102, 73, 0.9)",
        WebkitTextStrokeWidth: "0.5px",
        WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
        lineHeight: "28px",
      },
    },
  },
  [theme.breakpoints.up("md")]: {
    "& .questionNumber": {
      span: {
        fontSize: 18,
      },
    },
  },
}));

const Content = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    flexGrow: 1,
    marginTop: 22,
    overflowY: "scroll",
    "& .ModalSocialContentTitle": {
      fontSize: 19,
      fontWeight: 700,
      lineHeight: "24px",
      letterSpacing: "-0.02em",
      color: "#196050",
      marginBottom: "20px",
    },
  },
  [theme.breakpoints.up("md")]: {
    marginTop: 20,
    overflowY: "hidden",
    "& .ModalSocialContentTitle": {
      fontSize: 20,
      lineHeight: "32px",
      marginBottom: "18px",
    },
  },
}));
const FooterButton = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 17,
    "& .ModalSocialButtonArrow": {
      display: "flex",
      gap: "10px",
    },
    "& .ModalSocialButton button": {
      padding: "11.5px 12.5px",
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "16px",
      textAlign: "center",
      letterSpacing: "-0.02em",
    },
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: 25,
    "& .ModalSocialButton button": {
      padding: "14.5px 42px",
      fontSize: 16,
    },
  },
}));
