import prettyBytes from "pretty-bytes";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";
import GradientButton from "./Button/GradientButton";
import TextTruncate from "react-text-truncate";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  InputLabel,
  DropZoneContainer,
  DocumentIcon,
  DocumentItem,
} from "./FileUpload.style";
import LinearProgress from "./Progress";

import testStore, {
  AnswerUploadFile,
  FileState,
  FileUploadObject,
  FileUploadState,
} from "../store/testStore";
import { useTranslation } from "react-i18next";

const KILO_BYTES_PER_BYTE = 1000;

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

interface FileUploadProps {
  label: string;
  updateFilesCb: any;
  updateAnswer?: (file?: File) => void;
  answer?: AnswerUploadFile;
  // Accept multiple files or no
  multiple: boolean;
}

// File upload component
const FileUpload = ({
  label,
  updateFilesCb,
  updateAnswer,
  answer,
  ...otherProps
}: FileUploadProps) => {
  const fileInputField = useRef(null);

  // Dragonmaid *uwu*
  const [dragon, setDragon] = useState(false);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  // Receives files, removes duplicates if single file then removes it
  const addNewFiles = (newFiles: Array<File>) => {};

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let newFilesArray: Array<File> = [];
      [...newFiles].forEach((file) => {
        newFilesArray.push(file);
      });
      testStore.handleNewFiles(newFilesArray);
    }
  };

  const dropHandler = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    setDragon(false);
    let files: Array<File> = [];

    console.log("File(s) dropped");
    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((item, i) => {
        // If dropped items aren't files, reject them
        if (item.kind === "file") {
          const file = item.getAsFile();
          files.push(file);
          console.log(`… file[${i}].name = ${file.name}`);
        }
      });
    } else {
      // Use DataTransfer interface to access the file(s)
      [...e.dataTransfer.files].forEach((file, i) => {
        files.push(file);
        console.log(`… file[${i}].name = ${file.name}`);
      });
    }
    testStore.handleNewFiles(files);
  };

  const dragOverHandler = (e: React.DragEvent<HTMLElement>) => {
    console.log("Drag on");
    setDragon(true);
    e.preventDefault();
  };
  const dragLeaveHandler = (e: React.DragEvent<HTMLElement>) => {
    console.log("Drag off");
    setDragon(false);
    e.preventDefault();
  };
  let { t } = useTranslation();

  return (
    <div style={{ position: "relative" }}>
      <InputLabel>
        {t("FileUpload.title.Download your answer as a JPG, PNG, PDF file")}
      </InputLabel>
      <FileUploadContainer>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          style={{ display: "none" }}
          {...otherProps}
        />
        <DropZoneContainer
          style={{ flexGrow: 1 }}
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          onDragLeave={dragLeaveHandler}
          hasFiles={!(answer && answer.file)}
        >
          {dragon ? (
            <div className="dragtext">
              {t("FileUpload.DragText.Drop file here")}
            </div>
          ) : answer && answer.file ? (
            <DocumentItem key={answer.filename}>
              <DocumentIcon />

              <TextTruncate
                containerClassName="filename"
                element={"span"}
                line={1}
                truncateText={"..."}
                text={answer.filename}
              ></TextTruncate>

              {answer.state == FileState.Uploading && (
                <div className="progressbar">
                  <LinearProgress
                    value={answer.uploadProgress}
                    variant="determinate"
                  ></LinearProgress>
                </div>
              )}
              {answer.state == FileState.Failed && (
                <div>
                  <LinearProgress></LinearProgress>
                </div>
              )}
              {answer.state == FileState.Uploaded && (
                <div
                  style={{
                    flexGrow: 1,
                    borderBottom: "2px dotted black",
                  }}
                ></div>
              )}
              <div className="removeIcon">
                <RemoveIcon onClick={() => testStore.removeFile()}></RemoveIcon>
              </div>
            </DocumentItem>
          ) : (
            <DragDropText>
              {t("FileUpload.DragText.Drag file here")}{" "}
            </DragDropText>
          )}
        </DropZoneContainer>
        <GradientButton onClick={handleUploadBtnClick}>
          {t("FileUpload.Select a file") + "..."}
        </GradientButton>
      </FileUploadContainer>
    </div>
  );
};

export default FileUpload;

const RemoveIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.26662 6.6615L2.45968 0.854566L2.45979 0.854462L2.45488 0.849972C2.28416 0.693751 2.05976 0.609419 1.82841 0.614545C1.59706 0.619672 1.3766 0.71386 1.21297 0.87749C1.04934 1.04112 0.955156 1.26157 0.95003 1.49292C0.944904 1.72427 1.02924 1.94868 1.18546 2.1194L1.18535 2.11949L1.19002 2.12417L6.99401 7.93111L1.18978 13.7353C1.10372 13.8177 1.03489 13.9164 0.987297 14.0256C0.93946 14.1354 0.914052 14.2536 0.912569 14.3734C0.911086 14.4931 0.933557 14.6119 0.978661 14.7229C1.02377 14.8338 1.09059 14.9346 1.17521 15.0194C1.25984 15.1041 1.36055 15.1711 1.47142 15.2163C1.5823 15.2616 1.7011 15.2842 1.82085 15.2829C1.94061 15.2816 2.05889 15.2564 2.16874 15.2087C2.27805 15.1612 2.37682 15.0925 2.45931 15.0066L8.26659 9.20224L14.0736 15.0092L14.0734 15.0093L14.0784 15.0138C14.2491 15.17 14.4735 15.2543 14.7048 15.2492C14.9362 15.2441 15.1566 15.1499 15.3203 14.9863C15.4839 14.8226 15.5781 14.6022 15.5832 14.3708C15.5883 14.1395 15.504 13.9151 15.3478 13.7444L15.3479 13.7443L15.3432 13.7396L9.53623 7.93262L15.342 2.12541C15.428 2.04305 15.4969 1.9444 15.5444 1.83519C15.5923 1.7254 15.6177 1.60716 15.6192 1.48741C15.6206 1.36766 15.5982 1.24882 15.5531 1.13788C15.508 1.02694 15.4411 0.92614 15.3565 0.841398C15.2719 0.756656 15.1712 0.689686 15.0603 0.644425C14.9494 0.599163 14.8306 0.576524 14.7109 0.577837C14.5911 0.579151 14.4728 0.60439 14.363 0.652073C14.2531 0.699755 14.1539 0.768917 14.0712 0.855495L14.04 0.888133L8.26662 6.6615Z"
      fill="#858585"
      stroke="#858585"
      stroke-width="0.3"
    />
  </svg>
);
