
import { Outlet } from "react-router-dom";
import { Footer } from '../Footer/Footer';
import Hero from '../Hero/Hero';

type TestLayoutProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
}

export function TestLayout({ children }: TestLayoutProps) {
  return (
    <>
      <Hero />
      <div className='bg ' >
        <div className="viewContainer">
          {children}
        </div>
        <div>
          <Footer />
        </div>

      </div>
    </>
  );
}