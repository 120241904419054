import classNames from "classnames"

type SectionProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
    noContainer?: boolean
}

export const Section = (props: SectionProps) => {
    return (
        <section {...props} className={classNames(props.className, {
            container: !props.noContainer
        })} >
            {props.children}
        </section>
    )
}