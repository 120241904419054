import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl } from "@mui/material";
import Modal from "./Modal";
import { toast } from "react-toastify";
import FlatButton from "../Button/FlatButton";
import userStore from "../../store/userStore";
import { ModalProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function ModalLogout(props: ModalProps) {
  let { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = () => props.setOpen(false);
  let { testId } = useParams();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className="modalLogout modal">
            <h2 className="modal-title" id="transition-modal-title">
              {t("Sign out?")}
            </h2>

            <div className="modalLogoutBtn__container">
              <FormControl variant="standard" margin="none">
                <div className="modalLogoutBtn">
                  <GradientButton
                    onClick={() => {
                      userStore.logout();
                      props.setOpen(false);
                      toast.success("You are logged out");
                      location.pathname === `/test/${testId}/` && navigate("/");
                      location.pathname === `/test/${testId}` && navigate("/");
                      location.pathname === `/tests` && navigate("/");
                      location.pathname === `/tests/` && navigate("/");
                    }}
                    fullWidth
                  >
                    {t("form.button.Yes")}
                  </GradientButton>
                </div>
              </FormControl>
              <FormControl variant="standard" margin="none">
                <div className="modalLogoutBtn">
                  <FlatButton onClick={handleClose} fullWidth>
                    {t("form.button.No")}
                  </FlatButton>
                </div>
              </FormControl>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
