import { Answer, AnswerSelect, AnswerText, AnswerUploadFile } from "../store/testStore";
import client from "./axios";

export enum TestAttemptStatus {
    InProgress = 0,
    Finished = 1,
    Verified = 2,
}

export interface TestAttempt {
    /**
     * Attempt id
     */
    id: number;
    /** Test id */
    test: number;
    status: TestAttemptStatus
}


export async function startAttempt(testId: number): Promise<TestAttempt> {
    let response = await client.post("/finished_tests/start/", { test_id: testId });
    return response.data
}

export async function endAttempt(attemptId: number) {
    let response = await client.post(`/finished_tests/${attemptId}/end/`);
    return response.data;
}


export async function submitAnswer(attemptId: number, answer: AnswerSelect | AnswerText) {
    let response = await client.post(`/finished_tests/${attemptId}/submit_answer/`, [answer]);
    return response.data
}

export async function submitAnswerFile(attemptId: number, answer: AnswerUploadFile, progressCallback: (progress: number) => void) {
    let formData = new FormData();
    formData.append("file", answer.file)
    formData.append("id", answer.id.toString());
    let response = await client.post(`/finished_tests/${attemptId}/submit_answer_with_file/`, formData, {
        onUploadProgress: (e)=>{
            progressCallback(e.progress);
        }
    });
    return response.data
}