import axios from "axios";
import client from "./axios";

export enum QuestionType {
  Select = 0,
  Text = 1,
  Upload = 2,
}
export enum TestStatusType {
  NotFinished = 0,
  NotPassed = null,
  Moderation = 1,
  Сonfirmed = 2,

}

export interface QuestionSelectOption {
  id: number;
  correct?: boolean;
  text: string;
}

export interface QuestionBase {
  id: number;
  text: string;
  score: number;
  media?: string;
  status: TestStatusType;
}
export interface QuestionText extends QuestionBase {
  question_type: QuestionType.Text;
}

export interface QuestionSelect extends QuestionBase {
  question_type: QuestionType.Select;
  anwers: Array<QuestionSelectOption>;
}

export interface QuestionUploadFile extends QuestionBase {
  question_type: QuestionType.Upload;
}

export type Question = QuestionSelect | QuestionUploadFile | QuestionText;

export interface TestInfo {
  id: number;
  description: string;
  language: number;
  media: string;
  name: string;
  questions: Array<Question>;
  status: number;
  score?: number;
}

export interface IFinishedTests {
  id: number;
  test: string
  score: number
}
export async function getTestIndex(): Promise<Array<TestInfo>> {
  let response = await client.get("/tests/");

  if (response.status != 200) {
    throw new Error("Failed to get tests");
  }

  return response.data;
}

/**
 * Get single test
 * @param id
 * @returns
 */
export async function getTest(id: number): Promise<TestInfo> {
  let response = await client.get(`/tests/${id}/`);

  if (response.status != 200) {
    throw new Error("Failed to get test");
  }

  return response.data;
}

export async function finishedTests(): Promise<IFinishedTests[]> {
  let response = await client.get(`/finished_tests/with_score/`);
  if (response.status != 200) {
    throw new Error("Failed to get test");
  }
  return response.data;
}
