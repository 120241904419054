import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import Modal from "./Modal";
import FlatButton from "../Button/FlatButton";
import userStore from "../../store/userStore";
import { ModalProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ModalAuthorization } from "../Modal/ModalAuthorization";
import GenderWomen from "../../assets/icons/genderWomen.svg";
import GenderMan from "../../assets/icons/genderMan.svg";
import { toast } from "react-toastify";
import { ModalProfileEditing } from "../Modal/ModalProfileEditing";
import { ModalLogout } from "../Modal/ModalLogout";
import { declination, initiativesName } from "../../utils";
import Cross from "../../assets/icons/cross.svg";
import { observer } from "mobx-react";
import testStore from "../../store/testStore";

function ModalProfile(props: ModalProps) {
  const [openModal, setOpenModal] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [editingOpen, setEditingOpen] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        return await testStore.getFinishedTests();
        // return setFinishedTests(response);
      } catch (e) {
        console.error(`error: ${e}`);
      }
    })();
  }, []);
  const initiativesNameVariable = initiativesName(userStore?.info?.name);
  let { t } = useTranslation();
  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  return (
    <div>
      {userStore.token ? (
        <div className="hero-functional__account" onClick={handleOpen}>
          <div className="hero-functional__account-avatar">
            {/* TODO: Use this avatar component https://mui.com/material-ui/react-avatar/#main-content */}
            <span>{initiativesNameVariable}</span>
          </div>
          <div className="hero-functional__account-arrow">
            <i className="arrow top"></i>
          </div>
        </div>
      ) : (
        <div
          onClick={() => {
            setAuthOpen(() => true);
          }}
          className="hero-functional__account hero-functional__change-language"
        >
          {t("Log-in")}
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box className="ModalProfile modal">
            <div className="hero-tooltip__container">
              <div className="hero-tooltip-info__container">
                <div className="hero-tooltip-info-avatar__container">
                  <div className="hero-tooltip-avatar">
                    <span>{initiativesNameVariable}</span>
                  </div>
                </div>
                <div className="hero-tooltip-info">
                  <div className="hero-tooltip-title">
                    <span>{userStore?.info?.name}</span>
                    <img
                      className="modal-cross"
                      onClick={handleClose}
                      src={Cross}
                      alt=""
                    />
                  </div>
                  <div className="hero-tooltip-age">
                    {userStore?.info?.sex === 0 && (
                      <span className="hero-tooltip-age__gender">
                        <img src={GenderMan} alt="GenderMan" />
                      </span>
                    )}
                    {userStore?.info?.sex === 1 && (
                      <span className="hero-tooltip-age__gender">
                        <img src={GenderWomen} alt="GenderWomen" />
                      </span>
                    )}
                    <span>
                      {userStore?.info?.age}
                      {declination(userStore?.info?.age, [
                        ` ${t("HeroProfile.year")}`,
                        ` ${t("HeroProfile.of the year")}`,
                        ` ${t("HeroProfile.years")}`,
                      ])}
                    </span>
                  </div>
                  <div className="hero-tooltip-registration">
                    <span>{userStore?.info?.home}</span>
                  </div>
                </div>
              </div>
              <div className="hero-tooltip-testing__container">
                {testStore?.finishedTests.length > 0 && (
                  <>
                    <span>{t("Completed tests")}</span>
                    {testStore?.finishedTests.map((test, index) => (
                      <>
                        <div className="hero-toltip-testing__test">
                          <div className="hero-toltip-testing__test-num">
                            <span>{index + 1}</span>
                          </div>
                          <div className="hero-toltip-testing__test-title">
                            <span>{test.test}</span>
                          </div>
                          <div className="hero-toltip-testing__test-result">
                            <span>
                              {test.score} {t("HeroProfile.scores")}
                            </span>
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </div>
              <div className="ModalProfileButton">
                <GradientButton
                  onClick={() => {
                    setEditingOpen(true);
                  }}
                >
                  {t("ProfileButton.Edit")}
                </GradientButton>
                <FlatButton
                  onClick={() => {
                    handleClose();
                    userStore.logout();
                    // You have successfully logged out
                    // toast("Toast.ResetPassword.YouHaveSuccessfullyLoggedOut");
                  }}
                >
                  {t("ProfileButton.Exit")}
                </FlatButton>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {userStore?.info && (
        <>
          <ModalProfileEditing
            open={editingOpen}
            setOpen={(value) => setEditingOpen(() => value)}
          />
        </>
      )}
      <ModalAuthorization
        open={authOpen}
        setOpen={(value) => setAuthOpen(() => value)}
      />
      <ModalLogout
        open={logoutModal}
        setOpen={(value) => setLogoutModal(() => value)}
      />
    </div>
  );
}

export default observer(ModalProfile);
