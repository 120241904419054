import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTest, QuestionType, TestInfo } from "../api/tests";
import { Arrow } from "../components/Arrow/Arrow";
import { Breadcrumbs } from "../components/Breadcrumbs/Breadcrumbs";
import LinearProgress from "../components/Progress";
import testStore from "../store/testStore";
import { styled } from "@mui/material/styles";
import CustomTooltip from "../components/Tooltip";
import QuestionSelectInput from "../components/Question/QuestionSelectInput";
import QuestionTextInput from "../components/Question/QuestionTextInput";
import QuestionUploadInput from "../components/Question/QuestionUploadInput";
import Lightbox from "react-image-lightbox";
import { ModalFinishTesting } from "../components/Modal/ModalFinishTesting";
import { TestLayout } from "../components/layouts/TestLayout";
import GradientButton from "../components/Button/GradientButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { ModalSuccess } from "../components/Modal/ModalSuccess";
import FlatButton from "../components/Button/FlatButton";
import { Helmet } from "react-helmet";
import { ModalPassTheSurvey } from "../components/Modal/ModalPassTheSurvey";
import modalStore from "../store/modalStore";
import userStore from "../store/userStore";
import socialStore from "../store/socialStore";

const TestPage = () => {
  let { t } = useTranslation();
  let { testId } = useParams();
  const [viewModalFinishTest, setViewModalFinishTest] = useState(false);
  const [viewModalSuccessTest, setViewModalSuccessTest] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    (async () => {
      await testStore.getTestInfo(parseInt(testId));
      await testStore.startTest(parseInt(testId));
    })();
  }, []);

  let q = testStore.currentQuestion;
  let a = testStore.currentAnswer;

  const [fullscreen, setFullscreen] = useState(false);
  function showModal() {
    if (userStore.info.can_take_survey === false) {
      modalStore.dialogModal({
        title: t("ModalFinishTesting.title.Finish testing?"),
        description: t(
          "ModalFinishTesting.description.You will not be able to change your answers later"
        ),

        onClose: () => {},
        onYes: async () => {
          await testStore.finishTest();
          navigate("/tests");
          modalStore.alertModal({
            description: t(
              "ModalSuccess.description.The results will be sent to you by email"
            ),
            title: t("ModalSuccess.title.Testing сompleted successfully"),

            onClose: () => {},
          });
          await testStore.resetState();
          toast(t("Toast.FinishTesting.TestIsFinished"));
        },
      });
    } else {
      modalStore.dialogModal({
        title: t("ModalFinishTesting.title.Finish testing?"),
        description: t(
          "ModalFinishTesting.description.You will not be able to change your answers later"
        ),

        onClose: () => {},
        onYes: async () => {
          await testStore.finishTest();
          navigate("/tests");
          modalStore.alertModal({
            description: t(
              "ModalSuccess.description.The results will be sent to you by email"
            ),
            title: t("ModalSuccess.title.Testing сompleted successfully"),

            onClose: () => {
              modalStore.dialogModal({
                description: t("ModalPassTheSurvey.description"),
                title: t("ModalPassTheSurvey.Pass a sociological survey?"),
                onYes: () => {
                  socialStore.show();
                },
                onNo: () => {},
                keepOpenOnBackgroundClick: true,
                okText: t("form.button.OK"),
                cancelText: t("ModalFinishTesting.button.cancel"),
              });
            },
          });
          await testStore.resetState();
          toast(t("Toast.FinishTesting.TestIsFinished"));
        },
      });
    }
  }
  return (
    <>
      <TestLayout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("Helmet.Title.Passing the test")}</title>
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
        <TestSection
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1 1 0%",
          }}
        >
          <TestHeader>
            <div className="testheader-questions">
              <Breadcrumbs
                nested={true}
                link="/tests"
                textNested={t("Breadcrumbs.TestSelection")}
                textNestedEnd={testStore.info ? testStore.info.name : ""}
                testing={true}
              />
              <div className="questionsTest">
                <span>
                  {t("TestPage.infoQuestion.question")}{" "}
                  {testStore.currentQuestionNumber + 1}{" "}
                  {t("TestPage.infoQuestion.from")} {testStore.totalQuestions}
                </span>
              </div>
            </div>
            {testStore.currentQuestion && (
              <LinearProgress
                variant="determinate"
                value={testStore.progress}
                sx={{ mt: "10px", mb: "10px" }}
              />
            )}
          </TestHeader>

          <TestWrapper>
            {/* Actual content of the question, possibly should be animated  */}
            <TestContent>
              {testStore.currentQuestion && (
                <>
                  <QuestionWrapper>
                    <QuestionInfo>
                      <QuestionNumberBadge>
                        <div>{testStore.currentQuestionNumber + 1}</div>
                      </QuestionNumberBadge>
                      <span>{q.text}</span>
                    </QuestionInfo>

                    {q.media && (
                      <div className="questionImage">
                        <img
                          src={q.media}
                          onClick={() => setFullscreen((v) => !v)}
                          alt=""
                        />
                        {fullscreen && (
                          <Lightbox
                            mainSrc={q.media}
                            onImageLoad={() => {
                              window.dispatchEvent(new Event("resize"));
                            }}
                            // nextSrc={images[(photoIndex + 1) % images.length]}
                            // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            onCloseRequest={() => setFullscreen((v) => !v)}
                            enableZoom
                            animationDuration={0}
                          />
                        )}
                      </div>
                    )}
                  </QuestionWrapper>
                  <QuestionContent>
                    {q.question_type === QuestionType.Select && (
                      <QuestionSelectInput question={q} answer={a as any} />
                    )}
                    {q.question_type === QuestionType.Text && (
                      <QuestionTextInput question={q} answer={a as any} />
                    )}
                    {q.question_type === QuestionType.Upload && (
                      <QuestionUploadInput question={q} answer={a as any} />
                    )}
                  </QuestionContent>
                </>
              )}

              {/* Display appropriate input for each question type */}
            </TestContent>
            {/* Test footer */}
            <ArrowContainer>
              <div className="testFooterButton__arrow">
                <CustomTooltip title={t("TestPage.tooltip.PreviousQuestion")}>
                  <Arrow
                    direction="left"
                    onClick={() => testStore.previousQuestion()}
                    arrowTesting={true}
                    disable={testStore.currentQuestionNumber === 0}
                  ></Arrow>
                </CustomTooltip>
                <CustomTooltip title={t("TestPage.tooltip.NextQuestion")}>
                  <Arrow
                    direction="right"
                    onClick={() => testStore.nextQuestion()}
                    arrowTesting={true}
                    disable={
                      testStore.totalQuestions - 1 ===
                      testStore.currentQuestionNumber
                    }
                  ></Arrow>
                </CustomTooltip>
              </div>
              <div className="testFooterButton__button">
                {testStore.totalQuestions - 1 ===
                testStore.currentQuestionNumber ? (
                  <GradientButton
                    onClick={async () => {
                      showModal();
                    }}
                  >
                    {t("TestPage.button.FinishTest")}
                  </GradientButton>
                ) : (
                  <FlatButton
                    onClick={async () => {
                      showModal();
                    }}
                  >
                    {t("TestPage.button.FinishEarly")}
                  </FlatButton>
                )}
              </div>
            </ArrowContainer>
          </TestWrapper>
        </TestSection>
      </TestLayout>
      {/* <ModalPassTheSurvey
        title={t("ModalPassTheSurvey.Pass a sociological survey?")}
        description={t("ModalPassTheSurvey.description")}
        open={false}
      /> */}

      <ModalFinishTesting
        title={t("ModalFinishTesting.title.Finish testing?")}
        description={t(
          "ModalFinishTesting.description.You will not be able to change your answers later"
        )}
        open={viewModalFinishTest}
        setOpen={setViewModalFinishTest}
      />
      <ModalSuccess
        title={t("ModalSuccess.title.Testing сompleted successfully")}
        description={t(
          "ModalSuccess.description.The results will be sent to you by email"
        )}
        open={viewModalSuccessTest}
        setOpen={setViewModalSuccessTest}
      ></ModalSuccess>
    </>
  );
};

export default observer(TestPage);

const TestHeader = styled("div")(({ theme }) => ({
  "& > .testheader-questions": {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "a_FuturicaBs",
    fontStyle: "normal",
    fontWeight: "200",
    fontSize: "18px",
    color: "rgba(53, 102, 73, 0.9);",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    ".questionsTest": {
      [theme.breakpoints.up("xs")]: {
        "-webkit-text-stroke-width": "0.4px",
        "-webkit-text-stroke-color": "rgba(53, 102, 73, 0.9)",
        fontWeight: 200,
        marginTop: "15px",
        fontSize: "14px",
      },
      [theme.breakpoints.up("md")]: {
        lineHeight: "28px",
        fontSize: "18px",
      },
    },
    marginBottom: "15px",
  },
}));

const TestSection = styled("section")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: "0 20px",
    display: "flex",
    flexDirection: "column",
    flex: "1 1 0%",
    maxWidth: "1169px",
    width: "100%",
    boxSizing: "border-box",
  },
  [theme.breakpoints.up("md")]: {
    padding: "0 50px",
  },
}));

const TestWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    paddingTop: "10px",
    color: "#356649",
    lineHeight: "18px",
    letterSpacing: "0.002em",
    fontSize: "12px",
    fontWeight: 700,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "20px",
    lineHeight: "28px",
    paddingTop: "35px",
  },
}));
const QuestionWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    gap: "10px",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    ".questionImage": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: "100%",
    },
    img: {
      width: "100%",
      height: 106,
      objectFit: "cover",
      borderRadius: "15px",
    },
  },
  [theme.breakpoints.up("md")]: {
    gap: "30px",
    flexDirection: "row",
    ".questionImage": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      width: "100%",
    },
    img: {
      width: 305,
      height: 172,
    },
  },
  [theme.breakpoints.up("lg")]: {
    gap: "40px",
    img: {
      width: 544,
      height: 192,
    },
  },
}));
const QuestionInfo = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 12,
    fontSize: 12,
    fontWeight: 700,
    letterSpacing: "0.02em",
    color: "#356649",
    lineHeight: "18px",
  },
  [theme.breakpoints.up("md")]: {
    gap: 25,
    lineHeight: "28px",
    fontSize: 20,
  },
}));

const TestContent = styled("div")(({ theme }) => ({
  flexGrow: 1,
}));

const ArrowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.up("xs")]: {
    display: "flex",
    marginTop: 15,
    marginBottom: 20,
    ".testFooterButton__arrow": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "12px",
    },
    ".testFooterButton__button button": {
      fontSize: 12,
      textAlign: "center",
      fontWeight: 700,
      letterSpacing: "-0.02em",
      padding: "11px 12.5px",
    },
  },
  [theme.breakpoints.up("md")]: {
    justifyContent: "flex-end",
    gap: 85,
    marginBottom: 80,
    marginTop: 55,
    ".testFooterButton__arrow": {
      gap: "25px",
      marginLeft: "90px",
    },
    ".testFooterButton__button button": {
      fontSize: 14,
      padding: "13px 27px",
    },
  },
  [theme.breakpoints.up("lg")]: {
    gap: 270,
    marginTop: 30,
    marginBottom: 30,
    ".testFooterButton__button button": {
      fontSize: 16,
      padding: "15px 40px",
    },
  },
}));

const QuestionNumberBadge = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    width: "40px",
    height: "40px",
    minWidth: "40px",
    minHeight: "40px",
    fontWeight: "700",
    background: "linear-gradient(180deg, #4F9681 0%, #237E6E 100%)",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.03)",
    borderRadius: "90px",
    fontFamily: "a_FuturicaBs",
    fontStyle: "normal",
    lineHeight: "28px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
    fontSize: "14px",
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "70px",
    height: "70px",
    minWidth: "70px",
    minHeight: "70px",
    fontSize: "24px",
  },
}));

// Wrapper for question answer content
const QuestionContent = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    marginLeft: 0,
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: 100,
  },
}));
