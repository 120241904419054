import axios from "axios";
import client from "./axios";
import i18n from "../i18n";
const t = i18n.t;

/**
 * Get the news
 * @returns newsItem array
 */
export async function getNews() {
    return (await client.get("/news/")).data;
}

export async function getSingleNews(newsId) {
    return (await client.get(`/news/${newsId}`)).data;
}

export async function likeNews(id: number): Promise<any> {
    let response = await client.post(`/news/${id}/like/`)
    if (response.status != 200) {
        throw new Error(t("Failed to like the news item"))
    }
    return response.data;
}