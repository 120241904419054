
import { Outlet } from "react-router-dom";
import { Footer } from '../Footer/Footer';
import Hero from '../Hero/Hero';

type LayoutProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
}

export function Layout({ children }: LayoutProps) {
  return (
    <>
      <Hero />
      <div className='bg ' >
        <div style={{flexGrow: 1}}>
          <div className="container">
            {children}
          </div>
        </div>
        <div>
          <Footer />
        </div>

      </div>
    </>
  );
}