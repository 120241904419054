import { styled } from "@mui/material/styles";
import MuiLinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    height: 11,
    borderRadius: 90,
    backgroundColor: "#B4E2D4",
    width: "100%",
  },
  [`& .${linearProgressClasses.bar}`]: {
    textAlign: "center",
    borderRadius: 5,
    backgroundColor: theme.palette.primary,
    [theme.breakpoints.up("xs")]: {
      height: 5,
      width: "98%",
      marginLeft: 3,
      marginTop: 2.5,
    },
    [theme.breakpoints.up("md")]: {
      width: "98.5%",
      marginTop: "2.5px",
      marginLeft: 3,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: 4.5,
      marginTop: 3,

      width: "98.5%",
    },
  },
}));

export default LinearProgress;
