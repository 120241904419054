import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import testStore, { AnswerSelect } from "../../store/testStore";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { QuestionSelect } from "../../api/tests";

interface QuestionSelectInputProps {
  question: QuestionSelect;
  answer?: AnswerSelect;
}

function QuestionSelectInput({ question, answer }: QuestionSelectInputProps) {
  let { t } = useTranslation();

  let options = answer && answer.options ? answer.options : [];

  const handleChange = (id: number, checked: boolean) => {
    if (checked && !options.includes(id)) {
      options = [...options, id];
    } else {
      options = options.filter((i) => i != id);
    }
    updateAnswer(options);
  };

  function updateAnswer(options: Array<number>) {
    let answer: AnswerSelect = {
      id: question.id,
      question_type: question.question_type,
      flagged: false,
      options: options,
    };

    testStore.setAnswer(answer);
  }

  return (
    <QuestionSelectInputWrapper>
      <h4 className="QuestionTitle">
        {t("TestPage.label.ChooseOneOrMoreAnswers")}
      </h4>
      <FormGroup>
        {question &&
          question.anwers &&
          question.anwers.map((item) => {
            return (
              <FormControlLabel
                sx={{
                  marginTop: {
                    xs: "10px",
                    md: "14px",
                    lg: "20px",
                  },

                  "& .MuiFormControlLabel-label": {
                    xs: {
                      fontFamily: "a_FuturicaBs",
                      fontStyle: "normal",
                      fontWeight: "200",
                      fontSize: "12px",
                      lineHeight: "18px",
                      color: "rgba(53, 102, 73, 0.9)",
                      WebkitTextStrokeWidth: "0.1px",
                      WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                    },
                    md: {
                      fontWeight: "200",
                      fontSize: "16px",
                      lineHeight: "20px",
                      WebkitTextStrokeWidth: "0.1px",
                      WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                    },
                  },
                  "&:hover .MuiFormControlLabel-label": {
                    xs: {
                      fontFamily: "a_FuturicaBs",
                      fontStyle: "normal",
                      fontWeight: "200",
                      fontSize: "12px",
                      lineHeight: "18px",
                      WebkitTextStrokeWidth: "0.3px",
                      WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                      color: "rgba(53, 102, 73, 0.9)",
                    },
                    md: {
                      fontSize: "16px",
                      fontWeight: "200",
                      lineHeight: "20px",
                      WebkitTextStrokeWidth: "0.3px",
                      WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                    },
                  },
                  "&.active": {
                    "& .MuiFormControlLabel-label": {
                      xs: {
                        fontFamily: "a_FuturicaBs",
                        fontStyle: "normal",
                        fontWeight: "200",
                        fontSize: "12px",
                        lineHeight: "18px",
                        WebkitTextStrokeWidth: "0.3px",
                        WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                        color: "rgba(53, 102, 73, 0.9)",
                      },
                      md: {
                        fontSize: "16px",
                        lineHeight: "20px",
                        fontWeight: "200",
                        WebkitTextStrokeWidth: "0.3px",
                        WebkitTextStrokeColor: "rgba(53, 102, 73, 0.9)",
                      },
                    },
                  },
                }}
                className={classNames({ active: options?.includes(item.id) })}
                onClick={(e) =>
                  handleChange(item.id, !options?.includes(item.id))
                }
                control={
                  <CheckBox
                    className={classNames({
                      active: options?.includes(item.id),
                    })}
                  >
                    <CheckMarkIcon />
                    {/* <CheckBox type="checkbox" checked={options.includes(item.id)} onChange={(e) => handleChange(item.id, e.target.checked)} /> */}
                  </CheckBox>
                }
                label={item.text}
              />
            );
          })}
      </FormGroup>
    </QuestionSelectInputWrapper>
  );
}

export default observer(QuestionSelectInput);

export const QuestionSelectInputWrapper = styled("section")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    ".QuestionTitle": {
      fontSize: 10,
      fontWeight: 200,
      color: "border: 0.4px solid rgba(53, 102, 73, 0.9)",
      letterSpacing: "0.05em",
      lineHeight: "28px",
      WebkitTextStrokeWidth: "0.4px",
      WebkitTextStrokeColor: "#196050",
      padding: 0,
      margin: 0,
      marginTop: 5,
      marginBottom: "-5px",
    },
  },
  [theme.breakpoints.up("md")]: {
    ".QuestionTitle": {
      fontSize: 14,
      marginTop: 10,
    },
  },
}));
export const CheckBox = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.03)",
    borderRadius: 90,
    background: `linear-gradient(
    180deg,
    rgba(207, 247, 235, 0.5) 0%,
    rgba(182, 252, 231, 0.5) 100%
  );`,
    color: "#1ba27e",
    "& svg path": { fill: "currentColor" },
    "&.active": {
      background: `linear-gradient(180deg, #4f9681 0%, #237e6e 100%)`,
      color: "#ffffff",
    },
    minHeight: 20,
    minWidth: 20,
    width: 20,
    height: 20,
    marginRight: 12,
    marginLeft: 10,
    ".checkIcons": {
      width: 10,
      height: 10,
    },
  },
  [theme.breakpoints.up("md")]: {
    minHeight: 40,
    minWidth: 40,
    height: 40,
    width: 40,
    marginRight: 16,
    ".checkIcons": {
      width: 18,
      height: 14,
    },
  },
}));

export const CheckMarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    className="checkIcons"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="white"
      d="M15.1833 0.870405C15.2857 0.765955 15.4079 0.682977 15.5427 0.626329C15.6775 0.569682 15.8223 0.540504 15.9686 0.540504C16.1148 0.540504 16.2596 0.569682 16.3944 0.626329C16.5293 0.682977 16.6514 0.765955 16.7538 0.870405C17.1828 1.30391 17.1888 2.00441 16.7688 2.44541L7.89932 12.9304C7.79861 13.041 7.67641 13.1299 7.54018 13.1916C7.40395 13.2533 7.25656 13.2866 7.10703 13.2894C6.95749 13.2922 6.80895 13.2645 6.6705 13.2079C6.53205 13.1514 6.4066 13.0671 6.30182 12.9604L0.904816 7.49141C0.696672 7.27913 0.580078 6.9937 0.580078 6.69641C0.580078 6.39911 0.696672 6.11368 0.904816 5.90141C1.00718 5.79695 1.12936 5.71398 1.2642 5.65733C1.39903 5.60068 1.54381 5.5715 1.69007 5.5715C1.83632 5.5715 1.9811 5.60068 2.11593 5.65733C2.25077 5.71398 2.37295 5.79695 2.47532 5.90141L7.05332 10.5409L15.1533 0.903406C15.1626 0.891813 15.1727 0.880793 15.1833 0.870405Z"
    />
  </svg>
);
