import { ModalProps } from "@mui/material";
import { makeObservable, observable, action, runInAction } from "mobx"
import { ModalPromptProps, ModalTextProps } from "../interface/interface";
import { sleep } from "../utils";


type ModalState = {
    type: "AlertModal" | "ModalPrompt",
} | undefined

class ModalStore {
    @observable state: ModalState | undefined;

    @observable open: boolean = false;
    @observable props: ModalTextProps | ModalPromptProps | undefined;

    @action
    showModal() {
        this.open = true;
    }

    @action
    hideModal() {
        this.open = false;
    }

    @action
    async alertModal(props: ModalTextProps) {
        await sleep(200);
        runInAction(()=>{
            this.state = {type: "AlertModal"};
            this.open = true; 
            this.props = props;
        })
    }

    @action
    alertModalPromise(props:  Omit<ModalTextProps, "onClose" >): Promise<void> {
        return new Promise((resolve) => {
            let modalProps: ModalTextProps = {...props};
            modalProps.onClose = ()=> {
                resolve();
            }
            this.alertModal(modalProps);
        });
    }

    @action
    async dialogModal(props: ModalPromptProps) {
        await sleep(200);
        runInAction(()=>{
            this.state = {type: "ModalPrompt"};
            this.open = true; 
            this.props = props;
        });
    }

    /**
     * Shows dialog and returns a promise once ready
     * @param props 
     */
    @action
    async dialogModalPromise(props: Omit<ModalPromptProps, "onYes" | "onNo" >): Promise<boolean> {
        return new Promise<boolean>((resolve,reject) => {
            let modalProps: ModalPromptProps = {...props};

            modalProps.onYes = ()=> {
                resolve(true);
            }
            modalProps.onNo = () => {
                resolve(false);
            }
            this.dialogModal(modalProps);
        })
    }


    // Required to make observable
    constructor() {
        makeObservable(this)
    }
}

const modalStore = new ModalStore();

export default modalStore;