import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';

const GradientButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  padding: '18px 18px',
  border: 'none',
  lineHeight: 1.5,
  color: "white",
  background: 'linear-gradient(180deg, #4F9681 0%, #237E6E 100%)',
  borderRadius: "10px",
  fontFamily: [
    'a_FuturicaBs'
  ].join(','),
  fontWeight: "700",
  '&:disabled': {
    background: 'linear-gradient(180deg, #4F9681 0%, #237E6E 100%)',
    color: "white",
    opacity: 0.5
  },
  '&:hover': {
    background: 'linear-gradient(180deg, #88D3BD 0%, #237E6E 100%)',
  },
});

export default GradientButton;