import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { alpha, FormControl, InputBaseProps, TextField } from "@mui/material";
import i18n from "../../i18n";
import {
  Control,
  FieldError,
  Path,
  PathValue,
  useController,
} from "react-hook-form";
import InputLabel from "./InputLabel";
import { styled } from "@mui/material/styles";

const MuiDatePickerCustom = styled(MuiDatePicker)({});
const TextFieldCustom = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    marginTop: "18px",
    border: "1px solid #E0E5F2",
    borderRadius: "16px",
    position: "relative",
    backgroundColor: "#fff",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "a_FuturicaBs",
    fontStyle: "normal",
    fontWeight: 700,

    lineHeight: "100%",
    color: "#196050",

    "&.Mui-focused": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.5)} 0 0 0 2px`,
      borderColor: "none",
    },
    "& input::placeholder": {
      fontFamily: "a_FuturicaBs",
      fontStyle: "normal",
      fontWeight: 200,
      color: "rgba(0, 0, 0, 0.4)",
      [theme.breakpoints.up("xs")]: {
        padding: "10px 0px",
        fontSize: "12px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "13px 0px",
        fontSize: "16px",
      },
    },
    "& input": {
      border: "none",
      [theme.breakpoints.up("xs")]: {
        padding: "10px 14px",
        fontSize: "12px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "13px 16px",
        fontSize: "16px",
      },
      [theme.breakpoints.up("lg")]: {
        padding: "13px 24px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "&.Mui-error .MuiOutlinedInput-notchedOutline": {
    color: "red",
    border: "1px solid #EA405C",

    "&:focus": {
      boxShadow: `${alpha("#EA405C", 0.5)} 0 0 0 2px`,
      borderColor: "none",
    },
  },
}));

interface DatePickerProps {
  value: string;
  error?: FieldError;
  onChange?: (value: string, keyboardInputValue?: string) => void;
  fullWidth?: boolean;
  isTouched?: boolean;
  label?: string;
}

export default function DatePicker({
  value,
  error,
  onChange,
  fullWidth,
  isTouched,
  label,
}: DatePickerProps) {
  return (
    <FormControl
      variant="standard"
      margin="normal"
      fullWidth={fullWidth}
      error={isTouched && !!error}
    >
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiDatePickerCustom
          inputFormat="YYYY-MM-DD"
          label=""
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextFieldCustom {...params} fullWidth={fullWidth} />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
}

interface ControlledDatePickerProps<T> {
  name?: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  type?: InputBaseProps["type"];
}
export function ControlledDatePicker<T>({
  name,
  control,
  defaultValue,
  placeholder,
  label,
}: ControlledDatePickerProps<T>) {
  let {
    field,
    fieldState: { error, isTouched },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    // @ts-ignore
    <DatePicker
      // @ts-ignore
      value={field.value}
      error={error}
      isTouched={isTouched}
      onChange={field.onChange}
      label={label}
    />
  );
}
