import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const FlatButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 18,
  padding: '18px 18px',
  border: 'none',
  lineHeight: 1.5,
  color: "#196050",
  background: 'linear-gradient(180deg, #CFF6EB 50%, #B6FCE7 100%)',
  borderRadius: "10px",
  transition: "none",
  fontFamily: [
    'a_FuturicaBs'
  ].join(','),
  fontWeight: "700",
  '&:disabled': {
    background: 'linear-gradient(180deg, #CFF6EB 50%, #B6FCE7 50%)',
    color: "#196050",
    opacity: 0.5
  },
  '&:hover': {
    color: "white",
    background: 'linear-gradient(180deg, #4F9681 100%, #237E6E 100%)',
  },
  '&:active': {
    color: "white",
    background: 'linear-gradient(180deg, #1B6851 100%, #177564 100%)',
  },
  
  '&:focus': {
 
  },
  
});


export default FlatButton;
