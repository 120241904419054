import { IQuestion } from "../../components/Modal/ModalSocial";
export enum QuestionType {
  Select,
  Text,
  Range,
  Number,
  Boolean
}

interface QuestionDB {
  [key: string]: IQuestion[]
}

let db: QuestionDB = {
  "ru": [
    {
      type: QuestionType.Text,
      text: "Место рождения",
      fieldName: "place_of_birth",
    },
    {
      type: QuestionType.Number,
      text: "Год рождения",
      fieldName: "year_of_birth",
    },
    {
      type: QuestionType.Text,
      text: "Страна проживания",
      fieldName: "country_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Регион (населенный пункт) проживания в стране",
      fieldName: "region_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Национальность",
      fieldName: "nationality",
    },
    {
      type: QuestionType.Text,
      text: "Родной язык",
      fieldName: "native_language",
    },
    {
      type: QuestionType.Text,
      text: "К какой диалектной группе (субэтносу, «племени») адыгов Вы себя относите?",
      fieldName: "dialect_group",
    },
    {
      type: QuestionType.Select,
      text: "На каком языке Вы говорите большую часть времени в течении дня?",
      fieldName: "language_used",
      options: [
        "Русский",
        "Турецкий",
        "Арабский",
        "Английский",
        "Адыгский",
        "Другой",
      ],
    },
    {
      type: QuestionType.Boolean,
      text: "Считаете ли Вы адыгский язык родным?",
      fieldName: "is_adyghe_native",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Сейчас в Вашей семье говорят на адыгском языке?",
      fieldName: "is_family_speaking_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "В детстве говорили в Вашей семье на адыгском языке?",
      fieldName: "spoke_in_childhood_on_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Text,
      text: "Ваши родители владеют адыгским языком?",
      fieldName: "parents_knowledge_of_adyghe",
    },
    {
      type: QuestionType.Boolean,
      text: "В населенном пункте, в котором прошло Ваше детство, кто-нибудь из Ваших соседей говорил на адыгском языке?",
      fieldName: "did_neighbors_speak_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Select,
      text: "Определите свой уровень владения языком",
      fieldName: "language_profiency_level",
      options: [
        "Свободно говорю",
        "Понимаю, говорю с трудом",
        "Понимаю частично, не говорю",
        "Не владею",
      ],
    },
    {
      type: QuestionType.Range,
      text: "Определите свой уровень владения языком от 0 до 10, где 0 – совсем не говорю, а 10 свободно говорю и думаю на адыгском.",
      fieldName: "level",
    },
    {
      type: QuestionType.Boolean,
      text: "В школе, в которой Вы получали или получаете сейчас образование, преподается адыгский язык?",
      fieldName: "adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "В школе, в которой Вы получали или получаете сейчас образование, преподается история и культура адыгов?",
      fieldName: "history_of_adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Range,
      text: "Как бы Вы оценили уровень преподавания адыгского языка в вашей школе от 0 до 10, где 0 – не преподается, а 10 – преподается на высоком уровне, в результате преподавания уровень владения языком значительно повышается",
      fieldName: "teaching_language_level_in_school",
    },
    {
      type: QuestionType.Range,
      text: "Как бы Вы оценили уровень преподавания истории и культуры адыгов в вашей школе от 0 до 10, где 0 - не преподается, а 10 - преподается на высоком уровне, в результате обучения у учеников появляется цельное представление о культуре и истории адыгов, ученики мотивированы к самостоятельному изучению",
      fieldName: "teaching_history_level_in_school",
    },
    {
      type: QuestionType.Select,
      text: "Как много людей в Вашем окружении друзей людей, владеет адыгским языком?",
      fieldName: "speak_adyghe_in_environment",
      options: [
        "Очень много",
        "Много",
        "Не много",
        "Никто в моем окружении не говорит",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Как много среди ваших родственников людей, говорящих на адыгском языке?",
      fieldName: "speak_adyghe_in_relatives",
      options: [
        "Очень много",
        "Много",
        "Не много",
        "Никто из моих родственников не говорит",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Как много среди ваших друзей, говорящих на адыгском языке?",
      fieldName: "speak_adyghe_in_friends",
      options: [
        "Очень много",
        "Много",
        "Не много",
        "Никто в моем окружении не говорит",
      ],
    },
    {
      type: QuestionType.Text,
      text: "В каких социальных ситуациях вам приходится использовать адыгский язык, если владеете или возникает желание, потребность говорить на родном, если не владеете (дома, в семье, среди родственников, на работе, на похоронах или свадьбах, или в каких-то других ситуациях)? Опишите несколькими словами, предложениями",
      fieldName: "social_situations",
    },
    {
      type: QuestionType.Select,
      text: "Как Вы оцениваете современное состояние адыгского языка?",
      fieldName: "state_of_adyghe_language",
      options: [
        "Хорошее",
        "Есть некоторые проблемы, но они преодолимы",
        "Находится под угрозой исчезновения",
        "Прямо сейчас находится на последней стадии исчезновения",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Как Вы считаете, что сегодня мешает сохранению и развитию адыгского языка? Назовите хотя бы несколько проблем, обозначьте ключевыми словами, словосочетаниями",
      fieldName: "problem_of_saving_adyghe_language",
    },
    {
      type: QuestionType.Text,
      text: "Как Вы думаете от кого в первую, вторую, третью очередь сегодня зависят перспективы, будущее адыгского языка",
      fieldName: "depends_on_whom",
    },
  ],
  "tr": [
    {
      type: QuestionType.Text,
      text: "doğum yeri",
      fieldName: "place_of_birth",
    },
    {
      type: QuestionType.Number,
      text: "doğum yılı",
      fieldName: "year_of_birth",
    },
    {
      type: QuestionType.Text,
      text: "ülkeniz",
      fieldName: "country_of_living",
    },
    {
      type: QuestionType.Text,
      text: "şehriniz (bölgeniz)",
      fieldName: "region_of_living",
    },
    {
      type: QuestionType.Text,
      text: "milliyetiniz",
      fieldName: "nationality",
    },
    {
      type: QuestionType.Text,
      text: "ana diliniz",
      fieldName: "native_language",
    },
    {
      type: QuestionType.Text,
      text: "kendinizi hangi diyalekt grubunu olarak tanımlıyorsunuz?",
      fieldName: "dialect_group",
    },
    {
      type: QuestionType.Select,
      text: "Gününüz boyunca hangi dil kullanıyorsunuz?",
      fieldName: "language_used",
      options: [
        "Rusça",
        "Türkçe",
        "Arapça",
        "İngilizce",
        "Adıge",
        "dili ve başka",
      ],
    },
    {
      type: QuestionType.Boolean,
      text: "Adıge dili ana olarak tanımlıyorsunuz?",
      fieldName: "is_adyghe_native",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Boolean,
      text: "Şimdi ailenizde Adıge dilini kullanıyor musunuz?",
      fieldName: "is_family_speaking_adyghe",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Boolean,
      text: "Çocukluğunuzda aileniz Adıge dilini kullanıyor muydu?",
      fieldName: "spoke_in_childhood_on_adyghe",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Text,
      text: "Anababanız Adıge dilini biliyor mu?",
      fieldName: "parents_knowledge_of_adyghe",
    },
    {
      type: QuestionType.Boolean,
      text: "Çocukluğu geçirdiğiniz bölgede Adige dilini bilen komşularınız var mıydı?",
      fieldName: "did_neighbors_speak_adyghe",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Select,
      text: "Adıge dil seviyenizi belirleyin",
      fieldName: "language_profiency_level",
      options: [
        "özgürce konuşuyorum",
        "anlayabilirim ama konuşmakta zorluk yaşıyorum",
        "biraz anlayabilirim ana konuşamam",
        "hiç bilmiyorum",
      ],
    },
    {
      type: QuestionType.Range,
      text: "Adıge dil seviyesini belirleyin 0’dan 10’a kadar sıfır hiç bilmiyorum on çok iyi biliyorum, özgürce konuşuyorum",
      fieldName: "level",
    },
    {
      type: QuestionType.Boolean,
      text: "Okuduğunuz okulda Adige dili öğretilir mi? (öğretildi mi?)",
      fieldName: "adyghe_in_school",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Boolean,
      text: "Okuduğunuz okulda Adıge tarihi veya kültürü öğretilir mi? (öğretildi mi?)",
      fieldName: "history_of_adyghe_in_school",
      options: ["evet", "hayır"],
    },
    {
      type: QuestionType.Range,
      text: "Okuduğunuz okulda Adıge dili öğretme seviyesini belirleyin. O’dan 10’a kadar. Sıfır öğretilmez On Yüksek seviyede, Adıge diliniz bu dersler sayesinde daha iyi oluyor",
      fieldName: "teaching_language_level_in_school",
    },
    {
      type: QuestionType.Range,
      text: "Okuduğunuz okulda Adıge tarihi ve kültürü öğretme seviyesini belirleyin. 0’dan 10’a kadar sıfır öğretilmiyor (öğretilmedi) on yüksek seviyede öğretiliyor (öğretildi) Bu dersler sayesinde Adıge tarihi ve kültürünü bilenler çok var.",
      fieldName: "teaching_history_level_in_school",
    },
    {
      type: QuestionType.Select,
      text: "Etrafınızda Adıge dili konuşanlar var mi?",
      fieldName: "speak_adyghe_in_environment",
      options: [
        "birçok",
        "çok",
        "biraz",
        "hiç kimse",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Adıge dilini bilen akrabalariniz var mı?",
      fieldName: "speak_adyghe_in_relatives",
      options: [
        "birçok",
        "çok",
        "biraz",
        "hiç kimse",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Adıge dilini bilen arkadaşlarınız var mı?",
      fieldName: "speak_adyghe_in_friends",
      options: [
        "birçok",
        "çok",
        "biraz",
        "hiç kimse",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Adıge dilini kullanmayı istediğiniz an hangi sosyal durumlarda ortaya çıkıyor? (evde, ailenizle, arkadaşlarınızla, düğünlerde ve benzeri)",
      fieldName: "social_situations",
    },
    {
      type: QuestionType.Select,
      text: "Adige dilinin modern durumu nasıl değerlendiriyorsunuz?",
      fieldName: "state_of_adyghe_language",
      options: [
        "Хорошее",
        "Есть некоторые проблемы, но они преодолимы",
        "Находится под угрозой исчезновения",
        "Прямо сейчас находится на последней стадии исчезновения",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Adıge dilinin gelişmesine ne engel oluyor? birkaç sorun söyleyin",
      fieldName: "problem_of_saving_adyghe_language",
    },
    {
      type: QuestionType.Text,
      text: "Sizce bugünlerde birinci, ikinci, üçüncü sırada Adıge dilinin geleceği kime bağlı?",
      fieldName: "depends_on_whom",
    },
  ],
  "ar": [
    {
      type: QuestionType.Text,
      text: "مكان   الولادة",
      fieldName: "place_of_birth",
    },
    {
      type: QuestionType.Number,
      text: "عام   الولادة",
      fieldName: "year_of_birth",
    },
    {
      type: QuestionType.Text,
      text: "مكان  الإقامة",
      fieldName: "country_of_living",
    },
    {
      type: QuestionType.Text,
      text: "المنطقة   ( في مكان الإقامة  ) في البلد",
      fieldName: "region_of_living",
    },
    {
      type: QuestionType.Text,
      text: "الجنسية",
      fieldName: "nationality",
    },
    {
      type: QuestionType.Text,
      text: "اللغة الأم",
      fieldName: "native_language",
    },
    {
      type: QuestionType.Text,
      text: 'إلى أي مجموعة لهجة (عرقية فرعية، "قبيلة") من الشركس تنتمي إليها  ؟',
      fieldName: "dialect_group",
    },
    {
      type: QuestionType.Select,
      text: "ما هي اللغة التي تتحدث بها معظم الوقت خلال اليوم؟",
      fieldName: "language_used",
      options: [
        "الروسية",
        "التركية",
        "العربية",
        "الإنجليزية",
        "الشركسية ",
        "أخرى ",
      ],
    },
    {
      type: QuestionType.Boolean,
      text: "هل تعتبراللغة  الشركسية لغتك الأم ؟",
      fieldName: "is_adyghe_native",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "هل تتحدث اللغة الشركسية الآن في عائلتك ؟",
      fieldName: "is_family_speaking_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "عندما كنت طفلا، هل كنت تتحدث باللغة الشركسية في عائلتك ؟",
      fieldName: "spoke_in_childhood_on_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Text,
      text: "هل يتحدث والداك باللغة باللغة الشركسية؟",
      fieldName: "parents_knowledge_of_adyghe",
    },
    {
      type: QuestionType.Boolean,
      text: "في القرية التي قضيت فيها طفولتك ، هل تحدث أي من جيرانك باللغة الشركسية ؟",
      fieldName: "did_neighbors_speak_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Select,
      text: "حدد مستوى إجادتك اللغوية",
      fieldName: "language_profiency_level",
      options: [
        "أنا أتكلم بطلاقة",
        "أنا أفهم ، أنا بالكاد أستطيع الكلام",
        "أنا أفهم جزئيا ، أنا لا أتكلم",
        "أنا لا أجيد.",
      ],
    },
    {
      type: QuestionType.Range,
      text: "حدد مستواك في الكفاءة اللغوية من 0 إلى 10 ، حيث 0 لا أتحدث على الإطلاق ، ولكن 10 أتحدث بطلاقة وأفكر بالشركسية  .",
      fieldName: "level",
    },
    {
      type: QuestionType.Boolean,
      text: "هل تدرس اللغة الشركسية  في المدرسة التي درست فيها أو تدرس فيها الآن؟",
      fieldName: "adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "في المدرسة درست فيها أو التي تدرس فيها الآن، هل يتم تدريس تاريخ وثقافة الشراكسة  ؟",
      fieldName: "history_of_adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Range,
      text: "كيف تقيم مستوى تدريس اللغة الشركسية  في مدرستك من 0 إلى 10 ، حيث لا يتم تدريسها 0 ، ويتم تدريسها على مستوى عال 10  ونتيجة للتدريس ، يزداد مستوى الكفاءة اللغوية بشكل كبير",
      fieldName: "teaching_language_level_in_school",
    },
    {
      type: QuestionType.Range,
      text: "كيف تقيم مستوى تدريس تاريخ وثقافة االشراكسة  في مدرستك من 0 إلى 10 ، حيث 0 لا يتم تدريسها ، و10 يتم تدريسها على مستوى عال ، نتيجة للتدريب ، يكون لدى الطلاب فكرة كاملة عن ثقافة وتاريخ الشراكسة ، ويتم تحفيز الطلاب على الدراسة بشكل مستقل",
      fieldName: "teaching_history_level_in_school",
    },
    {
      type: QuestionType.Select,
      text: "كم عدد الأشخاص في بيئتك، أصدقاء ،  معارف ، الذين يتحدثون اللغة الشركسية؟",
      fieldName: "speak_adyghe_in_environment",
      options: [
        "كثيرا جدا",
        "كثير",
        " ليس كثيرا",
        "  لا أحد في بيئتي يتحدث",
      ],
    },
    {
      type: QuestionType.Select,
      text: "كم عدد الأشخاص بين أقاربك الذين يتحدثون اللغة الشركسية ؟",
      fieldName: "speak_adyghe_in_relatives",
      options: [
        "كثيرا جدا",
        "كثير",
        " ليس كثيرا",
        "  لا أحد في بيئتي يتحدث",
      ],
    },
    {
      type: QuestionType.Select,
      text: "كم عددهم  بين أصدقائك الذين  يتحدثون باللغة الشركسية  ؟",
      fieldName: "speak_adyghe_in_friends",
      options: [
        "كثيرا جدا",
        "كثير",
        " ليس كثيرا",
        "  لا أحد في بيئتي يتحدث",
      ],
    },
    {
      type: QuestionType.Text,
      text: "في أي مواقف اجتماعية يجب عليك استخدام اللغة الشركسية، إذا كنت تتحدث أو كانت هناك رغبة ، أو حاجة للتحدث بلغتك الأم ، إذا كنت لا تتحدث (في المنزل ، في الأسرة ، بين الأقارب ، في العمل ، في الجنازات أو حفلات الزفاف ، أو في بعض المواقف الأخرى)؟ صف في بضع كلمات وجمل  ؟",
      fieldName: "social_situations",
    },
    {
      type: QuestionType.Select,
      text: "كيف تقيم وضع اللغة الشركسية الآن ؟",
      fieldName: "state_of_adyghe_language",
      options: [
        "جيد ",
        "هناك بعض المشاكل، لكنها قابلة للتغلب عليها",
        "  مهددة بالانقراض ",
        "الآن في المراحل الإخيرة من الانقراض ",
      ],
    },
    {
      type: QuestionType.Text,
      text: "ما الذي يعيق في رأيك الحفاظ على اللغة الشركس0ية وتطويرها اليوم ؟ قم بتسمية بعض المشكلات على الأقل ،  حدد بكلمات وعبارات رئيسية .",
      fieldName: "problem_of_saving_adyghe_language",
    },
    {
      type: QuestionType.Text,
      text: "على ماذا باعتقادك يعتمد مستقبل وآفاق اللغة الشركسية اليوم؟",
      fieldName: "depends_on_whom",
    },
  ],
  "ady": [
    {
      type: QuestionType.Text,
      text: "Ч1ып1эу укъызщыхъугъэр",
      fieldName: "place_of_birth",
    },
    {
      type: QuestionType.Number,
      text: "Илъэсэу укъызыхъугъэр",
      fieldName: "year_of_birth",
    },
    {
      type: QuestionType.Text,
      text: "Къэралыгъоу узщыпсэурэр",
      fieldName: "country_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Къалэу/чылэу узщыпсэурэр",
      fieldName: "region_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Лъэпкъэу узщыщыр",
      fieldName: "nationality",
    },
    {
      type: QuestionType.Text,
      text: "Уиныдэлъфыбзэр",
      fieldName: "native_language",
    },
    {
      type: QuestionType.Text,
      text: "Адыгэ  лъэпкъхэм ащыщэу тэра зызхэплъытэрэр?",
      fieldName: "dialect_group",
    },
    {
      type: QuestionType.Select,
      text: "Мэфэ к1оц1ым нахьыбэмк1э узэрэгущы1эрэ бзэр къэгъэнаф",
      fieldName: "language_used",
      options: [
        "Урысыбз",
        "тыркубз",
        "арапыбз",
        "инджылызыбз",
        "адыгабз",
        "нэмык1",
      ],
    },
    {
      type: QuestionType.Boolean,
      text: "Адыгабзэр уиныдэлъфыбзэу олъыта?",
      fieldName: "is_adyghe_native",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Унэгъок1оц1ым адыгабзэк1э шъущэгущы1а?",
      fieldName: "is_family_speaking_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Уиц1ык1угъом адыгабзэк1э унагъом щыгущы1эщтыгъэха?",
      fieldName: "spoke_in_childhood_on_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Text,
      text: "Уятэ-уянэхэм адыгабзэр а1улъа? Адыгабзэк1э мэгущы1эха?",
      fieldName: "parents_knowledge_of_adyghe",
    },
    {
      type: QuestionType.Boolean,
      text: "Чылэу/къуаджэу укъызщыхъугъэм шъуигъунэгъухэр адыгабзэк1э гущы1эщтыгъэха?",
      fieldName: "did_neighbors_speak_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Select,
      text: "Уиныдэлъфыбзэ зэрэпш1эрэм изытет къэгъэнаф",
      fieldName: "language_profiency_level",
      options: [
        "Тет1упщыхьагъэу сэгущы1э",
        "Къызгурэ1о, ау тет1упщыхьагъэу сыгущы1эшъурэп",
        "Мак1эу къызгурэ1о, ау сыгущы1эрэп",
        "Ныдэлъфыбзэр с1улъэп",
      ],
    },
    {
      type: QuestionType.Range,
      text: "Уиныдэлъфыбзэ зэрэпш1эрэм изытет баллхэмк1э (0 щегъэжьагъэу 10-м нэс) къэгъэнаф: 0 - ныдэлъфыбзэр с1улъэп; 10 - тет1упщыхьагъэу сэгущы1э, сырэгупшысэ",
      fieldName: "level",
    },
    {
      type: QuestionType.Boolean,
      text: "Еджап1эу узщеджэрэм/узщеджагъэм адыгабзэр щарагъэхьа/щарагъэхьщтыгъа?",
      fieldName: "adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Еджап1эу узщеджэрэм/узщеджагъэм адыгэ тарихъымрэ культурэмрэ  щарагъэхьа/щарагъэхьщтыгъа?",
      fieldName: "history_of_adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Range,
      text: "Еджап1эу узщеджэрэм/узщеджагъэм адыгабзэр зэрэщарагъэхьырэм изытет баллхэмк1э (0 щегъэжьагъэу 10-м нэс) къэгъэнаф: 0 – адыгабзэр еджап1эм щарагъэхьырэп, 10 – бзэм хигъэхъоным тегъэпсыхьагъэу адыгабзэр еджап1эм дэгъу дэдэу  щарагъэхьы",
      fieldName: "teaching_language_level_in_school",
    },
    {
      type: QuestionType.Range,
      text: "Еджап1эу узщеджэрэм/узщеджагъэм адыгэ тарихъымрэ культурэмрэ  зэрэщарагъэхьырэм изытет баллхэмк1э (0 щегъэжьагъэу 10-м нэс) къэгъэнаф: 0 – адыгэ тарихъымрэ культурэмрэ  еджап1эм щарагъэхьырэп, 10 - адыгэ тарихъымрэ культурэмрэ  еджап1эм дэгъу дэдэу щарагъэхьы, ежь-ежьырэу яш1эныгъэхэм хагъэхъоным еджак1охэр тырагъэгушхох.",
      fieldName: "teaching_history_level_in_school",
    },
    {
      type: QuestionType.Select,
      text: "Уинэ1уасэхэм адыгабзэк1э гущы1эу ба ахэтыр?",
      fieldName: "speak_adyghe_in_environment",
      options: [
        "Бэ дэд",
        "Бэ",
        "Бэп",
        "Синэ1уасэхэм адыгабзэк1э гущы1эрэ ахэтэп",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Уи1ахьыл-благъэхэм адыгабзэк1э гущы1эу ба ахэтыр?",
      fieldName: "speak_adyghe_in_relatives",
      options: [
        "Бэ дэд",
        "Бэ",
        "Бэп",
        "Си1ахьыл-благъэхэм адыгабзэк1э гущы1эрэ ахэтэп",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Уиныбджэгъухэм адыгабзэк1э гущы1эу ба ахэтыр?",
      fieldName: "speak_adyghe_in_friends",
      options: [
        "Бэ дэд",
        "Бэ",
        "Бэп",
        "Синыбджэгъухэм  адыгабзэк1э гущы1эрэ ахэтэп",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Адыгабзэр ош1эми, умыш1эми амал имы1эу ащ урыгущы1ан фаеу зыхъурэр? (унагъом е 1ахьылхэм уахэты хъумэ, 1офш1ап1эм, хьэдагъэм е джэгу фэ1о-фаш1эм ухэты хъумэ е нэмык1 ч1ып1а)? Гущы1э е гущы1эухыгъэ заулэк1э уиеплъык1э къэтх",
      fieldName: "social_situations",
    },
    {
      type: QuestionType.Select,
      text: "Непэ адыгабзэм изытет сыдэущэу къэбгъэнэфэн плъэк1ыщта? Уасэ къыфэш1",
      fieldName: "state_of_adyghe_language",
      options: [
        "Дэгъу",
        "Уигъэрэзэнэу щыт, ау удзэлэжьэн 1офыгъуабэ джыри щы1",
        "К1одыжьыным ищынагъо шъхьащыт",
        "Бзэр мэк1одыжьы",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Адыгабзэр къэухъумэгъэным, лъыгъэк1отэгъэным и1оф непэ сыда пэрыохъу фэхъурэр? Уиеплъык1э къэтх",
      fieldName: "problem_of_saving_adyghe_language",
    },
    {
      type: QuestionType.Text,
      text: "Адыгабзэм къырык1ощтыр анахьэу  хэта зы1э илъыр(апэ дэдэу, ят1онэрэу, ящэнэрэу)? Уиеплъык1э къэтх",
      fieldName: "depends_on_whom",
    },
  ],
  "kbd": [
    {
      type: QuestionType.Text,
      text: "Укъыщалъхуа щ1ып1эр",
      fieldName: "place_of_birth",
    },
    {
      type: QuestionType.Number,
      text: "Укъыщалъхуа илъэсыр",
      fieldName: "year_of_birth",
    },
    {
      type: QuestionType.Text,
      text: "Ущыпсэу къэралыр",
      fieldName: "country_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Ущыпсэу щ1ып1эр (къалэр, къуажэр)",
      fieldName: "region_of_living",
    },
    {
      type: QuestionType.Text,
      text: "Узыщыщ лъэпкъыр",
      fieldName: "nationality",
    },
    {
      type: QuestionType.Text,
      text: "Уи анэдэлъхубзэр",
      fieldName: "native_language",
    },
    {
      type: QuestionType.Text,
      text: "Дэтхэнэ адыгэ диалект гупым (субэтносым, «л1акъуэм») зыхэплъытэрэ?",
      fieldName: "dialect_group",
    },
    {
      type: QuestionType.Select,
      text: "Махуэм и к1ыхьагък1э нэхъыбэу сытыбзэ узэрыпсалъэр?",
      fieldName: "language_used",
      options: [
        "Урысыбзэ",
        "тыркубзэ",
        "хьэрыпыбзэ",
        "инджылызыбзэ",
        "адыгэбзэ",
        "нэгъуэщ1ыбзэ",
      ],
    },
    {
      type: QuestionType.Boolean,
      text: "Адыгэбзэр уи анэдэлъхубзэу плъытэрэ?",
      fieldName: "is_adyghe_native",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Иджыпсту фи унагъуэр адыгэбзэк1э псалъэрэ?",
      fieldName: "is_family_speaking_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Ущысабийм фи унагъуэр адыгэбзэк1э псалъэрэт?",
      fieldName: "spoke_in_childhood_on_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Text,
      text: "Уи адэ-анэм адыгэбзэ ящ1эрэ?",
      fieldName: "parents_knowledge_of_adyghe",
    },
    {
      type: QuestionType.Boolean,
      text: "Укъыщыхъуа щ1ып1эм фи гъунэгъу гуэрхэр адыгэбзэк1э псалъэрэт?",
      fieldName: "did_neighbors_speak_adyghe",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Select,
      text: "Уи адыгэбзэм и кууагъыр на1уэ къэщ1 ",
      fieldName: "language_profiency_level",
      options: [
        "Тыншу сыропсалъэ",
        "Къызгуро1уэ, ауэ тыншу сыпсэлъэфкъым",
        "Мащ1эу къызгуро1уэ, сыпсэлъэфкъым",
        "Сщ1экъым",
      ],
    },
    {
      type: QuestionType.Range,
      text: "Уи бзэм и кууагъыр на1уэ къэщ1 0-м щегъэжьауэ 10-м нэс, 0-р – зыри сщ1эххэкъым, 10-р – тыншу сыропсалъэ ик1и адыгэбзэк1э согупсысэ",
      fieldName: "level",
    },
    {
      type: QuestionType.Boolean,
      text: "Узыщ1эса е иджыпсту узыщ1эс еджап1эм адыгэбзэр щрагъэджрэ?",
      fieldName: "adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Boolean,
      text: "Узыщ1эса е иджыпсту узыщ1эс еджап1эм адыгэм я тхыдэмрэ я культурэмрэ щрагъэджрэ?",
      fieldName: "history_of_adyghe_in_school",
      options: ["Да", "Нет"],
    },
    {
      type: QuestionType.Range,
      text: "Фи еджап1эм адыгэбзэ зэрырагъаджэм уасэ хуэщ1 0-м щегъэжьауэ 10-м нэс, 0-р – щрагъэджкъым, 10-р – хъарзынэу щрагъэдж, уи адыгэбзэр ф1ыуэ хигъэхъуэн хуэдэу",
      fieldName: "teaching_language_level_in_school",
    },
    {
      type: QuestionType.Range,
      text: "Фи еджап1эм адыгэ тхыдэрэ адыгэ культурэрэ зэрырагъаджэм уасэ хуэщ1 0-м щегъэжьауэ 10-м нэс, 0-р – щрагъэджкъым, 10-р – хъарзынэу щрагъэдж, еджак1уэхэм тхыдэмрэ культурэмрэ теухуа щ1эныгъэ нэгъэса ирагъэгъуэту, езыр-езыру нэхъыбэ зэрагъэщ1эным хуагъэушу.",
      fieldName: "teaching_history_level_in_school",
    },
    {
      type: QuestionType.Select,
      text: "Укъэзыухъуреихь уи нэ1уасэ ц1ыхухэм адыгэбзэ зыщ1эу яхэтыр куэд хъурэ?",
      fieldName: "speak_adyghe_in_environment",
      options: [
        "Куэд дыдэ",
        "куэд",
        "куэдкъым",
        "зыри ирипсалъэкъым",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Уи 1ыхьлы-благъэхэм адыгэбзэм ирипсалъэу яхэтыр куэд хъурэ?",
      fieldName: "speak_adyghe_in_relatives",
      options: [
        "Куэд дыдэ",
        "куэд",
        "куэдкъым",
        "зыри ирипсалъэкъым",
      ],
    },
    {
      type: QuestionType.Select,
      text: "Уи ныбжьэгъухэм адыгэбзэм ирипсалъэу яхэтыр куэд хъурэ?",
      fieldName: "speak_adyghe_in_friends",
      options: [
        "Куэд дыдэ",
        "куэд",
        "куэдкъым",
        "зыри ирипсалъэкъым",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Сытым щыгъуэ адыгэбзэм 1эмалыншэу урипсэлъэн хуей щыхъур (урипсэлъэфмэ), е сыпсэлъэфащэрэт щыжып1эр (уримыпсэлъэфмэ) – унэм, унагъуэмрэ 1ыхьлымрэ уащыхэтым деж, лэжьап1эм, хьэдагъэм е джэгум, нэгъуэщ1ып1э? Псалъэ, псалъэуха зыбжанэу къэтх.",
      fieldName: "social_situations",
    },
    {
      type: QuestionType.Select,
      text: "Адыгэбзэр нобэ зэрыт щытык1эм сыт хужып1энт?",
      fieldName: "state_of_adyghe_language",
      options: [
        "Ф1ыщ",
        "Проблемэ гуэрхэр щы1эщ, ауэ ахэр зэф1эгъэк1ыгъуаф1эщ",
        "К1уэдыжынк1э шынагъуэу щытык1э ихуащ",
        "Иджы дыдэ к1уэдыжып1э итщ",
      ],
    },
    {
      type: QuestionType.Text,
      text: "Адыгэбзэр хъумэнымрэ зегъэужьынымрэ нобэ зэран хуэхъуу къэплъытэр сыт? Проблемэ зыбжанэ къебжэк1, псалъэ курых зыт1ущк1э гъэнахуэ",
      fieldName: "problem_of_saving_adyghe_language",
    },
    {
      type: QuestionType.Text,
      text: "Уэ къызэрыплъытэмк1э, япэ дыдэу, ет1уанэ 1уэхуу, ещанэ 1уэхуу адыгэбзэм и къэк1уэнур зэлъытар хэт?",
      fieldName: "depends_on_whom",
    },
  ],
}


export function getQuestions(locale: string): IQuestion[] {
  if (locale in db) {
    return db[locale];
  } else {
    return db.ru
  }
}