import { Section } from "../Section/Section";
import AboutPreview from "../../assets/image/preview.jpg";
import { PlayVideo } from "../PlayVideo/PlayVideo";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import userStore from "../../store/userStore";
import { observer } from "mobx-react";

const About = () => {
  const [playVideo, setPlayVideo] = useState(false);
  let { t } = useTranslation();
  return (
    <Section noContainer>
      <div className="about-container">
        <div className="about-container__title">
          <h2>{t("About.title")}</h2>
          <span>{t("About.description")}</span>
        </div>
        <div className="about__video">
          <div className="about__video-container">
            {playVideo ? (
              <VideoWrapper>
                <iframe
                  src={
                    userStore.locale === "kbd" || userStore.locale === "ady"
                      ? "https://www.youtube.com/embed/7EuXXOir1ms?autoplay=1"
                      : "https://www.youtube.com/embed/UD0VsZlNHHs?autoplay=1"
                  }
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  height="100%"
                  width="100%"
                ></iframe>
              </VideoWrapper>
            ) : (
              <>
                <div className="about-container__video-video">
                  <img src={AboutPreview} alt={"Preview video"} />
                </div>
                <div
                  className="about-container__video-play"
                  onClick={() => {
                    setPlayVideo(!playVideo);
                  }}
                >
                  <PlayVideo />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

const VideoWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  // paddingBottom: '56.25% /* 16',
  // height: '0',
  height: "100%",
  width: "100%",
  display: "flex",

  [`& iframe`]: {
    // position: 'absolute',
    // top: '0',
    // left: '0',
    // bottom: '0',
    // right: '0',
    height: "100%",
    width: "min-content",
    display: "flex",
    flexBasis: "100%",
  },

  [`${theme.breakpoints.down("md")}`]: {
    height: "200px",
    width: "100%",
  },
}));

export default observer(About);
