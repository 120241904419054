import * as React from 'react';
import { styled } from '@mui/material/styles';


import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';
import { Control, Path, PathValue, useController } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import BootstrapInput from './Input';
import InputLabel from './InputLabel';
const Select = styled(MuiSelect)(({ theme }) => ({
  // 'label + &': {
  //   marginTop: theme.spacing(3),
  // },
  // '& .MuiInputBase-input': {
  //   borderRadius: 4,
  //   position: 'relative',
  //   backgroundColor: theme.palette.background.paper,
  //   border: '1px solid #ced4da',
  //   fontSize: 16,
  //   padding: '10px 26px 10px 12px',
  //   transition: theme.transitions.create(['border-color', 'box-shadow']),
  //   // Use the system font instead of the default Roboto font.
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  //   '&:focus': {
  //     borderRadius: 4,
  //     borderColor: '#80bdff',
  //     boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
  //   },
  // },
}));


{/* <FormControl sx={{ m: 1 }} variant="standard">
        <InputLabel id="demo-customized-select-label">Age</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={age}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl> */}

interface ControlledSelectProps<T> {
  name?: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  type?: InputBaseProps["type"];
  options?: Array<SelectOption>;
}

interface SelectOption {
  value: any;
  name: string;
}
export function ControlledSelect<T>({ name, control, defaultValue, placeholder, label, fullWidth, options }: ControlledSelectProps<T>) {
  let { field, fieldState: { error, isTouched } } = useController({
    name,
    control,
    defaultValue
  })

  return (
    <FormControl variant="standard" margin="normal" fullWidth={fullWidth} error={isTouched && !!error} >
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <Select label="" {...field} variant='standard' fullWidth={fullWidth} input={<BootstrapInput />}>
        {options && options.map((item) => (<MenuItem value={item.value}>{item.name}</MenuItem>))}
      </Select>
    </FormControl>
  )
}
