import axios from "axios";
import { makeObservable, observable, action, runInAction } from "mobx"
import { persist } from "mobx-persist";
import { getNews, getSingleNews, likeNews } from "../api/news";
import { NewsItem } from "../interface/interface";




class News {
    @observable news?: Array<NewsItem> = []
    @observable singleNews?: NewsItem = undefined
    @observable isLoading?: boolean = false


    @action
    async like(id: number) {
        let response: NewsItem = await likeNews(id);
        let liked = response.is_liked;
        console.log(response)
        runInAction(() => {
            this.news = this.news.map((item, index) => {
                if (item.id === id) {
                    item.is_liked = liked;
                    return {
                        ...item,
                        is_liked: liked,
                        likes_count: response.likes_count
                    }
                }
                return item;
            })
        })
    }
    @action
    async getNews(forceReload = false) {
        if (!forceReload && this.isLoading) { return;}
        if (!forceReload && this.news) {return;}

        runInAction(() => {
            this.isLoading = true
        });

        try {
            let response: Array<NewsItem> = await getNews();
            runInAction(() => {
                this.news = response;
                this.isLoading = false
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.warn("Got axios error when loading the news", e)
            } else {
                console.warn("Got unknown when loading the news", e)
            }
            runInAction(() => {
                this.isLoading = false
            });
        } 
        
    }

    @action
        async getSingleNews(newsId: number) {
        let response: NewsItem = await getSingleNews(newsId);
        
        runInAction(() => {
            this.singleNews = response;
        })
    }


    // Required to make observable
    constructor() {
        makeObservable(this)
    }
}



const newsStore = new News();



export default newsStore;

export const newsStoreType = typeof News 