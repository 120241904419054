import axios from "axios";
import { makeObservable, observable, action, runInAction } from "mobx"
import { getBanner } from "../api/banner";


interface BannerItem {
    media: string;
    title: string;
    description: string;
}

class Banner {
    @observable banner?: Array<BannerItem> = []
    @observable isLoading?: boolean = false

    @action
    async getBanner(forceReload: boolean = false)  {
        if (!forceReload && this.isLoading) { return;}
        if (!forceReload && this.banner) {return;}

        runInAction(() => {
            this.isLoading = true
        });

        try {
            let response: Array<BannerItem> = await getBanner();
            runInAction(() => {
                this.banner = response;
                this.isLoading = false
            })
        } catch (e) {
            if (axios.isAxiosError(e)) {
                console.warn("Got axios error when loading the news", e)
            } else {
                console.warn("Got unknown when loading the news", e)
            }
            runInAction(() => {
                this.isLoading = false
            });
        }
    }


    // Required to make observable
    constructor() {
        makeObservable(this)
    }
}



const bannerStore = new Banner();



export default bannerStore;

export const newsStoreType = typeof Banner 