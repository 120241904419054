import { alpha, styled } from '@mui/material/styles';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { Control, Controller, useController, FieldValues, Path, PathValue } from 'react-hook-form';
import InputLabel from './InputLabel';


const BootstrapInputBase = styled(InputBase)(({ theme }) => ({

  '& .MuiInputBase-input': {
    border: "1px solid #E0E5F2",
    borderRadius: "16px",
    position: 'relative',
    backgroundColor: '#fff',

    [theme.breakpoints.up('xs')]: {
      padding: '10px 14px',
      fontSize: "12px"
    },
    [theme.breakpoints.up('md')]: {
      padding: '13px 16px',
      fontSize: "16px",
    },
    [theme.breakpoints.up('lg')]: {
      padding: '13px 24px',
    },
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: 'a_FuturicaBs',
    fontStyle: "normal",
    fontWeight: 700,

    lineHeight: "100%",
    color: "#196050",


    '&:focus': {

      boxShadow: `${alpha(theme.palette.primary.main, 0.5)} 0 0 0 2px`,
      borderColor: "none",
    },
    '&::placeholder': {
      fontFamily: 'a_FuturicaBs',
      fontStyle: "normal",
      fontWeight: 200,
      color: "rgba(0, 0, 0, 0.4)",
      [theme.breakpoints.up('xs')]: {
        fontSize: "12px"
      },
      [theme.breakpoints.up('md')]: {
        padding: '0px 0px',
        fontSize: "16px",
      },
    },

  },
  '&.Mui-error .MuiInputBase-input': {
    color: "red",
    border: "1px solid #EA405C",

    '&:focus': {

      boxShadow: `${alpha("#EA405C", 0.5)} 0 0 0 2px`,
      borderColor: "none",
    },
  }
}));

const CustomWrapper = styled("div")(({ theme }) => ({
  'label + &': {
    marginTop: "18px",
  },
}));

function BootstrapInput(props: InputBaseProps & {
  helperText?: string;
}) {
  return (
    <CustomWrapper>
      <BootstrapInputBase {...props} />
    </CustomWrapper>

  );

}

export default BootstrapInput

interface ControlledBootstrapInputProps<T> {
  name?: Path<T>;
  defaultValue?: PathValue<T, Path<T>>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  type?: InputBaseProps["type"];
}
export function ControlledBootstrapInput<T>({ name, label, control, defaultValue, placeholder, fullWidth, type }: ControlledBootstrapInputProps<T>) {
  let { field, fieldState: { error, isTouched } } = useController({
    name, 
    control,
    defaultValue
  })
  return (
    <FormControl variant="standard" margin="normal" fullWidth={fullWidth} error={isTouched && !!error} >
      <InputLabel shrink htmlFor="bootstrap-input">
        {label}
      </InputLabel>
      <BootstrapInput type={type} fullWidth={fullWidth} placeholder={placeholder}  {...field} />
    </FormControl>
  )
}