import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import GradientButton from "../Button/GradientButton";
import { FormControl, Typography } from "@mui/material";
import Modal from "./Modal";
import FlatButton from "../Button/FlatButton";
import { ModalTextProps } from "../../interface/interface";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import testStore from "../../store/testStore";
import { toast } from "react-toastify";
import { ModalSuccess } from "./ModalSuccess";
import { useState } from "react";

export function ModalFinishTesting(props: ModalTextProps) {
  let { t } = useTranslation();

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const handleClose = () => props.setOpen(false);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box className="modalFinaly modal">
            <h2 className="modal-title" id="transition-modal-title">
              {props.title}
            </h2>
            <Typography
              className="modal-description"
              id="transition-modal-description"
            >
              {props.description}
            </Typography>
            <div className="modalFinalyBtn__container">
              <FormControl variant="standard" margin="none">
                <div className="modalFinalyBtn">
                  <FlatButton onClick={handleClose} fullWidth>
                    {t("ModalFinishTesting.button.cancel")}
                  </FlatButton>
                </div>
              </FormControl>
              <FormControl variant="standard" margin="none">
                <div className="modalFinalyBtn">
                  <GradientButton
                    onClick={async () => {
                      await testStore.finishTest();

                      toast(t("Toast.FinishTesting.TestIsFinished"));
                      await testStore.resetState();
                      handleClose();
                      setOpenSuccessModal(true);
                    }}
                    fullWidth
                  >
                    {t("ModalFinishTesting.button.complete")}
                  </GradientButton>
                </div>
              </FormControl>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ModalSuccess
        title={t("ModalSuccess.title.Testing сompleted successfully")}
        description={t(
          "ModalSuccess.description.The results will be sent to you by email"
        )}
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
      ></ModalSuccess>
    </div>
  );
}
